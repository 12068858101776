import React from 'react'
import CustomFormField from './customForm'
import { ExtendedTableColumn } from '../../type/type'
import { FormInstance } from 'antd'
import { UploadProps } from 'antd/lib'
const FormHosting = (props: { formData: ExtendedTableColumn[],isLoading: boolean,formRef:FormInstance ,onSubmit: () => void ,fileList:any[],propsUpload:UploadProps}) => {
  return (
    <>
      {props.formData.map(
        (column: ExtendedTableColumn) =>
          column && (
            <div className={` px-6 py-2 relative`} key={column.view}>
              <CustomFormField showPreview={true} 
              column={column} defaultValue={''} 
              formRef={props.formRef} 
              onClick={props.onSubmit}
               isLoading={props.isLoading}
               fileList = {props.fileList}
               propsUpload={props.propsUpload}
               

               />
            </div>
          )
      )}
    </>
  )
}
export default FormHosting
