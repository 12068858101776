import React from 'react'

const Deactivated = () => {
  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-50 p-4'>
      <div className='max-w-md w-full bg-white shadow-lg rounded-lg p-6 sm:p-8 text-center'>
        <h1 className='text-xl text-gray-800 mb-4'>Account Deactivated</h1>
        <p className='text-gray-600 mb-4'>
          You do not have access. If you believe this is an error, please contact{' '}
          <a href='https://support.tacitbase.com/' style={{ color: '#009D79' }}>
            support
          </a>{' '}
          for assistance.
        </p>
      </div>
    </div>
  )
}

export default Deactivated
