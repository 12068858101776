import { IntegratedMember, Integration, ThirdPartyIntegrations } from "../../type/type"
import { initCurrentOrg } from "./initialState";

export function init_org_members(state: any, action: any) {
  var lists: any = {};
  action.payload.map((list: any) => {
    if (!list.applicants) {
      list.applicants = [];
    }
    lists[list.id] = list;
  });
  return {
    ...state,
    boardList: lists,
  };
}

export function show_org(state: any, action: any) {
  return {
    ...state,
    loginUser: {
      ...state.loginUser,
      user:{
        ...state.loginUser.user,
        registrations: [...state.loginUser.user.registrations, action.payload],
      }
    },
  };
}


export function update_org_Id_Name(state: any, action: any) {
  
  return {
    ...state,
    applicationId: action.data.applicationId,
    applicationName:action.data.applicationName,
    applicationImgUrl:action.data.applicationImgUrl,
    currentOrg:{
      ...state.currentOrg,
      id: action.data.applicationId,
      name: action.data.applicationName,
      display_name: action.data.applicationName,
    }
  };
}

export function add_current_org(state:any,action:any){
  return {
    ...state,
    currentOrg:action.data
  }
}
export function update_current_org_creation_stages(state:any,action:any){
  return {
    ...state,
    currentOrg:{...state.currentOrg,setup_stages:action.data}
  }
}
export function init_current_org(state:any){
  return {
    ...state,
    currentOrg:initCurrentOrg
  }
}


export function change_loader_status(state:any,action:any){
  return {
    ...state,
    pageAndContentLoader:action.data
  }
}


export function add_chat_integration(state:any,action:any){

  return {
    ...state,
    chatsIntegration:action.data
  }
}

export function update_chat_integration(state:any,action:any){

  return {
    ...state,
    chatsIntegration:[...state.chatsIntegration,action.data]
  }
}

export function integrated_slack_members(state: any, action: {
  payload: IntegratedMember[]
}) {
  let integratedMembers: { [key: string]: Integration[] } = {}
  action.payload.map((member: IntegratedMember) => {
    integratedMembers[member.tacitbase_user_id] = member.integrations
  })
  return {
    ...state,
    integratedSlackMember: integratedMembers,
  }
}

export function disconnect_slack_member(state: any, action: {
  payload: string
}) {
  const newIntegratedSlackMembers = Object.keys(state.integratedSlackMember)
    .filter(userId => userId !== action.payload)
    .reduce((result: any, current: any) => {
      result[current] = state.integratedSlackMember[current]
      return result
    }, {})

  return {
    ...state,
    integratedSlackMember: newIntegratedSlackMembers,
  }
}
export function integrate_member_to_slack(state: any, action: {
  payload: IntegratedMember[]
}) {
  let integratedMembers: { [key: string]: Integration[] } = {}
  action.payload.map((member: IntegratedMember) => {
    integratedMembers[member.tacitbase_user_id] = member.integrations
  })
  return {
    ...state,
    integratedSlackMember: { ...state.integratedSlackMember, ...integratedMembers },
  }
}

export function init_platform(state: any, action: {
  payload: ThirdPartyIntegrations[]
}) {
  let platform: {
    [key: string]: string
  } = {}
  action.payload.map((integration: ThirdPartyIntegrations) => {
    platform[integration.integration_name] = integration.platform_image
  })
  return {
    ...state,
    jobPlatform: platform
  }
}