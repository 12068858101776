import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";

dayjs.extend(utc);
dayjs.extend(duration);

export const renderCreatedAt = (created_at: string): string => {
    // Parse timestamp as UTC to avoid timezone issues
    const createdAtDay = dayjs.utc(created_at);
    // Get current time in UTC
    const currentTimeDay = dayjs.utc();
    const timeDiff = dayjs.duration(currentTimeDay.diff(createdAtDay));
    const isToday = createdAtDay.isSame(currentTimeDay, 'day');
    const isSameYear = createdAtDay.isSame(currentTimeDay, 'year');

    if (isToday) {
        if (timeDiff.asMinutes() < 1) {
            return "Just now"
        } else if (timeDiff.asMinutes() < 60) {
            return `${Math.floor(timeDiff.asMinutes())} minutes ago`;
        } else {
            return `${Math.floor(timeDiff.asHours())} hours ago`;
        }
    } else if (isSameYear) {
        return createdAtDay.format('D MMM [at] h:mm a');
    } else {
        return createdAtDay.format('D MMM YYYY [at] h:mm a');
    }
};
