import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CurrentCandidateInPool } from "../../../../../redux/reducers/initialState";
import { Skeleton, Space,  Tag, Typography } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import { Attachment } from "../../../../../type/type";
import { capitalizeFirstLetter } from "../../../../../utils/capitalizeFirstLetter";
import { ATTACHMENTS, CANDIDATES } from "../../../../../constant";
import { PDFRenderer } from "../../../../../PDFViewer";
import { getAttachments } from "../../../../../lib/api";
import { Dispatch } from "redux";
import { addAttachmentToCurrentCandidateInAtsPool } from "../../../../../redux/actions/opening/action";
const { Text } = Typography;

type candidateInPoolAttachmentsProps = {
  currentCandidateInPool: CurrentCandidateInPool;
  addAttachmentToCurrentCandidateInAtsPool: (attachments: Attachment[]) => void;
};
const CandidateInPoolAttachments = (props: candidateInPoolAttachmentsProps) => {
  const [attachments, setAttachments] = useState<Attachment[]>([
    {
      id: "",
      name: "",
      objectKey: "",
      modelName: "",
      recordID: "",
      presignedLink: "",
      created_at: "",
      updated_at: "",
      opening_id: "",
    },
  ]);
  const [selectedCandidateAttachement, setSelectedAttachment] =
    useState<Attachment>({
      id: "",
      name: "",
      objectKey: "",
      modelName: "",
      recordID: "",
      presignedLink: "",
      created_at: "",
      updated_at: "",
      opening_id: "",
    });
  const onCloseViewer = () => {
    setSelectedAttachment({
      id: "",
      name: "",
      objectKey: "",
      modelName: "",
      recordID: "",
      presignedLink: "",
      created_at: "",
      updated_at: "",
      opening_id: "",
    });
  };
  const handleSelectedAttachment = (attachment: Attachment) => {
    setSelectedAttachment(attachment);
  };
  if (!props.currentCandidateInPool?.attachments) return null;
  useEffect(() => {
    getAttachments(CANDIDATES, props.currentCandidateInPool.candidate.id).then(
      (res) => {
        //  console.log(res)
        props.addAttachmentToCurrentCandidateInAtsPool(res);
      }
    );
  }, [props.currentCandidateInPool.candidate]);

  useEffect(() => {
    setAttachments(props.currentCandidateInPool?.attachments);
  }, [props.currentCandidateInPool?.attachments]);
  return (
    <div>
      <>
        {attachments?.length > 0 && (
          <Space direction="vertical" className="w-full">
            <Text strong>{capitalizeFirstLetter(ATTACHMENTS)}</Text>
            <div className="space-y-2 w-full">
              {attachments?.map((attachment: Attachment) => (
                <Tag
                  className="cursor-pointer w-full flex items-center border border-black border-opacity-5 p-2"
                  bordered={false}
                  key={attachment.id}
                  onClick={() => handleSelectedAttachment(attachment)}
                >
                  <FilePdfOutlined style={{ fontSize: "32px" }} />
                  <Text
                    ellipsis={{ tooltip: attachment.name }}
                    style={{ width: "100%" }}
                  >
                    {attachment.name}
                  </Text>
                </Tag>
              ))}
              
            </div>
          </Space>
        )}
        {selectedCandidateAttachement.id.length > 0 && (
          <PDFRenderer
            title={selectedCandidateAttachement.name}
            attachmentOfwhom={
              props.currentCandidateInPool.candidate.first_name +
              " " +
              props.currentCandidateInPool.candidate.last_name
            }
            onCloseViewer={onCloseViewer}
            url={selectedCandidateAttachement.presignedLink}
          ></PDFRenderer>
        )}
          {props.currentCandidateInPool.newAttachmentUploader?.fileList?.length > 0 && (
        <Space direction="vertical" className="w-full">
      {attachments.length==0&&(  <Text strong>{capitalizeFirstLetter(ATTACHMENTS)}</Text>)}
        <div className="space-y-2 pt-2 w-full">
          {[...Array(props.currentCandidateInPool.newAttachmentUploader?.fileList?.length)].map((_, index) => (
            <Tag
              key={index}
              className="cursor-pointer w-full flex items-center space-x-2 border border-black border-opacity-5 p-2"
              bordered={false}
            >
              <Skeleton.Avatar active shape="square" size="default" />
              <Skeleton.Input active size="default" style={{ width: '100%' }} />
            </Tag>
          ))}
        </div>
      </Space>
      )}
       
      </>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  currentCandidateInPool: state.opening.currentCandidateInPool,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  addAttachmentToCurrentCandidateInAtsPool: (attachments: Attachment[]) =>
    dispatch(addAttachmentToCurrentCandidateInAtsPool(attachments)),
});

export default connect(
  mapStateToProps,
  mapPropsToState
)(CandidateInPoolAttachments);
