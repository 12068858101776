import React, { useEffect, useState } from 'react'
import { ConfigProvider, Layout } from 'antd'
import Sider from 'antd/es/layout/Sider'
import { connect } from 'react-redux'
import { Opening } from './type/type'
import { DoubleRightOutlined, DoubleLeftOutlined } from '@ant-design/icons'
import { useLocation } from 'react-router-dom'
import { TACITBASE, titleMapping } from './constant'
import SideNav from './components/sidenav/SideNav'
import SettingMenuPopover from './components/sidenav/SettingMenuPopover'
import Navbar from './components/newNavbar/Navbar'
import { isMainProductURL, isPublicRoute } from './utils/regExMatcher'
import { Footer } from 'antd/es/layout/layout'
import { capitalizeFirstLetter } from './utils/capitalizeFirstLetter'
import Link from 'antd/es/typography/Link'

const { Content } = Layout

const Tacitbase = (props: {
  currentOpening: Opening
  children: React.ReactNode
  applicationId: string
  stripeCustomerStatus: any
  applicationName: string
  applicationImgUrl: string
}) => {
  const [collapsed, setCollapsed] = useState(false)
  const location = useLocation()
  const [isPublic, setIsPublic] = useState(false)
  const [ismainProductRoute, setIsmainProductRoute] = useState(false)
  useEffect(() => {
    const path = location.pathname
    const routeParts = path.split('/')
    const lastRoutePart = routeParts[routeParts.length - 1]
    const secondLastRoutePart = routeParts[routeParts.length - 2]
    if (secondLastRoutePart === 'settings' && lastRoutePart) {
      const tabName = lastRoutePart.charAt(0).toUpperCase() + lastRoutePart.slice(1)
      document.title = `Settings | ${tabName}`
    } else if (titleMapping[lastRoutePart]) {
      const title = titleMapping[lastRoutePart] || lastRoutePart
      document.title = `${title} | ${TACITBASE}`
    }

    const isPublic = isPublicRoute(path)
    const isMainProduct = isMainProductURL(path)
    // const isOnBoarding = isOnBoardingURL(path);
    if (isPublic) {
      setIsPublic(true)
      //   setIsOnboardingRoute(false)
      setIsmainProductRoute(false)
    } else if (isMainProduct) {
      setIsPublic(false)

      setIsmainProductRoute(true)
      //   setIsOnboardingRoute(false)
    } else {
      setIsPublic(false)

      setIsmainProductRoute(false)
      //   setIsOnboardingRoute(false);
    }
  }, [location.pathname])

  // Effect hook to calculate and update content height on window resize
  useEffect(() => {
    const calculateHeight = () => {
      const totalHeight = window.innerHeight
      const headerHeight = 64 // replace with your actual header height
      const dynamicHeight = totalHeight - headerHeight
      //   setContentHeight(`${dynamicHeight}px`);
    }

    // Initial calculation
    calculateHeight()

    // Update the height if the window is resized
    const handleResize = () => {
      calculateHeight()
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // Render default layout if none of the specific routes match
  const renderDefaultLayout = () => {
    return (
      <Layout className={`h-screen flex fixed sm:w-full md:w-full w-full `} style={{ minHeight: '100vh' }}>
        <Content className='overflow-auto h-full bg-white'>{props.children}</Content>
      </Layout>
    )
  }

  if (isPublic) {
    return renderDefaultLayout()
  }

  if (ismainProductRoute) {
    return (
      <ConfigProvider
        theme={
          props.currentOpening.color || props.currentOpening.background_image
            ? {
                components: {
                  Layout: {
                    headerBg: 'hsla(0,0%,0%,0.4)',
                    colorText: 'white',
                    colorBgContainer: undefined,
                    siderBg: 'hsla(0,0%,0%,0.4)',
                    triggerBg: 'hsla(0,0%,0%,0.4)',
                    triggerHeight:35,
                    lightSiderBg: 'hsla(0,0%,0%,0.4)'
                  },
                  Menu: {
                    colorText: 'white',
                    colorBgContainer: undefined,
                    itemBg: `${`bg-color-primary2`}`
                  }
                }
              }
            : {
                components:{
                    Layout:{
                        triggerHeight:35
                    }
                }
            }
        }
      >
        <Layout
          style={{
            background: props.currentOpening.background_image ? `url(${props.currentOpening.color}) center center / cover no-repeat` : ''
          }}
          className={`bg-color-${props.currentOpening.color} h-screen flex fixed w-full`}
        >
          <Navbar />
          <Layout
            className={`bg-color-${props.currentOpening.color}`}
            style={{
              background: props.currentOpening.background_image ? `url(${props.currentOpening.color}) center center / cover no-repeat` : ''
            }}
          >
            <Sider
              style={{
                borderRight: 'hsla(0,0%,100%,0.25)',
                borderRightWidth: '1px',
                borderRightStyle: 'solid', paddingBottom:34,
                left: 0
                // marginTop: "52px",
              }}
              theme='light' zeroWidthTriggerStyle={{height:35}}
              trigger={collapsed ?<DoubleRightOutlined /> : <DoubleLeftOutlined />}
              collapsible
              collapsed={collapsed}
              onCollapse={(value) => setCollapsed(value)}
            >
              <div
                style={{
                  display: 'flex',
                  borderBottom: '1px solid rgba(255,255,255,0.5)',
                //   borderBottomWidth: '1px',
                //   borderBlockEndStyle: 'solid',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  height: '100%'
                }}
              >
                <SideNav />
                <SettingMenuPopover isCollaps={collapsed} />
              </div>
            </Sider>
            <Layout
              className={`bg-color-${props.currentOpening.color}`}
              style={{
                background: props.currentOpening.background_image
                  ? `url(${props.currentOpening.color}) center center / cover no-repeat`
                  : ''
              }}
            >
              <Content
                className={`bg-color-${props.currentOpening.color} `}
                style={{
                  margin: 0,
                  minHeight: 280,
                  background: props.currentOpening.background_image
                    ? `url(${props.currentOpening.color}) center center / cover no-repeat`
                    : ''
                }}
              >

                
                <div style={{ height: 'calc(-87px + 100vh)' }} className={`flex flex-col h-full text-black`}>
                  <div className='flex flex-col overflow-x-hidden h-full'>{props.children}</div>
                </div>

                <Footer
                  style={{
                    textAlign: 'center',
                    padding: 0,
                    color: props.currentOpening.background_image || props.currentOpening.color ? 'white' : 'black',
                    height: 35,
                    borderTop:
                     '1px solid rgba(255,255,255,0.25)',
                    background: props.currentOpening.background_image || props.currentOpening.color ? 'hsla(0, 0%, 0%, 0.4)' : 'white'
                  }}
                  className=' bg-transparent'
                >
                  <div className='h-full' style={{ background: '' }}>
                    <div className='flex justify-center w-full items-center h-full'>
                      <span className='text-xs'>
                        {capitalizeFirstLetter('Powered by')}{' '}
                        <Link
                          target='_blank'
                          href='https://www.tacitbase.com/'
                          className='font-semibold text-sm'
                          style={{ color: '#F26046' }}
                        >
                          Tacitbase
                        </Link>
                      </span>
                    </div>
                  </div>
                </Footer>
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </ConfigProvider>
    )
  }
  return renderDefaultLayout()
}

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  currentOpening: state.opening.currentOpening,
  applicationName: state.opening.applicationName,
  applicationImgUrl: state.opening.applicationImgUrl,

  stripeCustomerStatus: state.opening.billingCustomer.status
})

export default connect(mapStateToProps)(Tacitbase)