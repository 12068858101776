import React, { useEffect } from "react"
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Dispatch } from "redux";
import { getOpeningMembers } from "../../../lib/api";
import { initOpeningMembership } from "../../../redux/actions/opening/action";
import { Opening, orgMember, openingMemberType, OpeningMembers, Member, loginUser } from "../../../type/type"
import { Avatar, Divider } from "antd"
import TacitbaseAvtar from "../../../ant/Avtar"

type BoardMemberProps = {
  currentOpening: Opening;
  openingMembers: OpeningMembers;
  member: orgMember;
  applicationId: string;
  initOpeningMembers(member: any, openingId: string): void;
  loginUser: loginUser;
};

const BoardMember = (props: BoardMemberProps) => {
  let boardUrlParam: any = {};
  boardUrlParam = useParams();

  useEffect(() => {
    getOpeningMembers(boardUrlParam.board_id, props.applicationId).then((members: Member[]) => {
      props.initOpeningMembers(members, boardUrlParam.board_id);
    });
  }, [boardUrlParam]);

  if (!props.openingMembers[props.currentOpening.id]) return null;
  return (
    <div className="flex items-center mr-1" style={{height:30}}>
        <Avatar.Group maxCount={4} size={"default"}>
          {props.loginUser.user.imageUrl ?
            <TacitbaseAvtar
              toolTip={`${props.loginUser.user?.firstName}  ${props.loginUser.user?.lastName}` }
              src={props.loginUser.user.imageUrl}
              size="default"
            /> : <> <TacitbaseAvtar
              toolTip={`${props.loginUser.user?.firstName}  ${props.loginUser.user?.lastName}` }
              content={props.loginUser.user?.firstName[0] + props.loginUser.user?.lastName[0]}
              size="default"
            /></>}
        </Avatar.Group>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  openingMembers: state.opening.openingMembers,
  member: state.opening.members,
  currentOpening: state.opening.currentOpening,
  applicationId: state.opening.applicationId,
  loginUser: state.opening.loginUser,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initOpeningMembers: (members: any, openingId: string) => {
    dispatch(initOpeningMembership(members, openingId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BoardMember)
