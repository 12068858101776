import React, { useEffect, useState } from 'react'
import './App.css'
import Board from './pages/Board'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Tacitbase from './Tacitbase'
import Login from './Login'
import Openings from './Openings'
import Pool from './components/boardPanel/createList/jobPost/pool/Pool'
import PageNotFound from './PageNotFound'
import LandingPage from './LandingPage'
import PageLoader from './LoadingScreen'
import ApplicantDetailView from './components/boardPanel/createList/jobPost/pool/ApplicantDetailView'

function AgencyPortalApp() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000) // Adjust the delay as needed

    return () => clearTimeout(timer)
  }, [])

  if (loading) {
    return (
      <PageLoader
      />
    ) 
  }

  return (
    <Router>
      <Tacitbase>
        <Switch>
          <Route exact path='/' component={LandingPage} />
          <Route exact path='/identity/:base64String' component={Login} />
          <Route exact path='/o/:org_id/homepage' component={Openings} />
          <Route  key= ":board_id" path='/o/:org_id/b/:board_id/:tabname' component={Board} />  

          <Route path='*' component={PageNotFound} />
        </Switch>
        <Route key=":candidate_id" path="/o/:org_id/b/:board_id/:tabname/:candidate_id" component={ApplicantDetailView} /> 
      </Tacitbase>
    </Router>
  )
}

export default AgencyPortalApp
