import React from "react";
import { connect } from "react-redux"
import {  MEMBER_ONLY, PUBLIC } from "../../../constant";
import { loginUser, Opening } from "../../../type/type.d";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { Typography } from "antd"
const { Text } = Typography

type ShowBoardStatusProps = {
  currentOpening: Opening;
  loginUser: loginUser;
};
const ShowBoardStatus = (props: ShowBoardStatusProps) => {
  
  return (
    <div>
      <div
        role="button"
        tabIndex={0} style={{height:30}}
        onKeyDown={() => {}}
        className={`${
          props.currentOpening.access.admin
            ? "cursor-pointer"
            : "cursor-default"
          } isEditable  flex justify-start items-center space-x-1  text-sm  text-white rounded-default `}
      >
        <Text className="text-white">
          {props.currentOpening.member_only
            ? capitalizeFirstLetter(MEMBER_ONLY)
            : capitalizeFirstLetter(PUBLIC)}
        </Text>{" "}
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  loginUser: state.opening.loginUser,
});
export default connect(mapStateToProps)(ShowBoardStatus);
