import React, { useEffect, useRef, useState } from "react"
import { registerShortcutHandler, unregisterShortcutHandler } from "./hook/addHandler";
import OutsideClick from "./OutsideClick";

interface OverlayProps {
    className?: string;
    closeOnEscape?: boolean;
    closeOnOutsideClick?: boolean;
    onClose: () => void;
    children: any;
    scope: any;
    reference?:any
  }
export const ELEVATION_ATTR = "data-elevation";
export const ELEVATION_SELECTOR = `[${ELEVATION_ATTR}]`;
  export const getHighestVisibleElevation = () => {
    const elevatedElements = document.querySelectorAll(ELEVATION_SELECTOR);
    let highestElevation = 0;
    for (const elem of elevatedElements) {
      const elevation = elem ? Number(elem.getAttribute(ELEVATION_ATTR)) : 0;
      if (elevation > highestElevation) {
        highestElevation = elevation;
      }
    }
  
    return highestElevation;
  };
export const Overlay = (props: OverlayProps) => {
    const [elevation] = useState(() => getHighestVisibleElevation() + 1);
    const contentsRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
      registerShortcutHandler(props.onClose, {
        scope: props.scope,
      });
      return () => { unregisterShortcutHandler(props.onClose) }
    }, []);
  props.reference &&
    OutsideClick(props.reference,props.onClose);
    const ELEVATION_ATTR = "data-elevation";
  
    return (
      <div
        ref={contentsRef}
        className="z-10 normal-case "
        {...{ [ELEVATION_ATTR]: elevation }}
      >
        {props.children}{" "}
      </div>
    );
  };