import React, { useState, useEffect } from "react";

//redux stuff
import { connect } from "react-redux";
import { updateOpeningName } from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { useRef } from "react";
// import OutsideClick from "../../OutsideClick";
import { BoardInputProps } from "../../../type/subNavbar";
import { Opening } from "../../../type/type";

const BoardInput = (props: BoardInputProps) => {
  const [boardInput, showBoardInput] = useState(false);
  const [board, updateBoard] = useState("");
  const txtRef = useRef<HTMLInputElement | null>(null);
  // OutsideClick(txtRef, () => {
  //   showBoardInput(false);
  // });

  useEffect(() => {
    updateBoard(props.boardName);
  }, [props.boardName]);
  return (
    <React.Fragment>
      
        <div
            className={`isEditable rounded flex w-auto space-y-2 trucate min-w-min  items-center justify-center text-white focus:outline-none  py-1  mr-1 font-semibold text-base tracking-wider line-clamp-1
`}
          onClick={() => showBoardInput(!boardInput)}
          role="button"
          tabIndex={0}
          ref={txtRef}
          onKeyDown={() => showBoardInput(!boardInput)}
        >
          {props.boardName}
        </div>
      
    </React.Fragment>
  );
};
const mapStateToProps = (state: any) => ({
  currentBoard: state.opening.currentOpening,
  socketClientID: state.opening.clientId,
  applicationId: state.opening.applicationId,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  editOpeningName: (board: Opening) => dispatch(updateOpeningName(board)),
});

export default connect(mapStateToProps, mapPropsToState)(BoardInput);
