import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import BoardInput from "./boardInput/BoardInput"
// import StarredBoard from "./starredBoard/StarredBoard";
import BoardMember from "./memberProfile/BoardMember"
import { companyBoards, notification, Opening, OrganizedBoard } from "../../type/type.d"
import ShowBoardMembers from "./addBoardMember/ShowBoardMembers"
// import Read from "../../Read"
import EyeIconSvg from "../../assets/EyeIconSvg"
import { useParams } from "react-router-dom"
import { Space } from "antd"
import { Dispatch } from "redux"
import { initCurrentBoard, removeCurrentBoard } from "../../redux/actions/opening/action"
import NavigationJobPosts from "./NavigationJobPosts"
import ShowBoardStatus from "./boardStatus/ShowBoardStatus"
type SubNavbarProps = {
  currentOpening: Opening,
  notificationReduxState: notification,
  companyBoard: companyBoards[],
  click: () => void,
  sliderState: boolean,
  showPopup?: () => void,
  roles: string[],
  showCal: () => void,
  toggleValue: boolean,
  openings: OrganizedBoard,
  removeCurrentBoard(): void,
  saveCurrentBoard(board: Opening): void

}
const SubNavbar: React.FC<SubNavbarProps> = (props: SubNavbarProps) => {
  const [watchStatus, setWatchStatus] = useState(false)
  const params: { board_id: string, org_id: string } = useParams()

  useEffect(() => {
    if (props.notificationReduxState.currentBoardWatcher) {
      if (
        props.notificationReduxState.currentBoardWatcher.entity_id ==
        params.board_id
      ) {
        setWatchStatus(true)
      } else {
        setWatchStatus(false)
      }
    }
  }, [props.notificationReduxState.currentBoardWatcher])



  return (
    <React.Fragment>
      <div className="board-main-content pt-px h-10 items-center flex">
        <div className="h-full
         flex-wrap space-y-1  flex items-end text-white justify-between ">
          <div className="flex space-x-5">
            <Space>
              <div className="flex items-center">

                <Space>
                  <BoardInput boardName={props.currentOpening.name} />
                </Space>
                <NavigationJobPosts />
              </div>

            </Space>
            <ShowBoardStatus/>
            <BoardMember />
            <Space>
            </Space>
          </div>
          <div className="flex font-light">
            {" "}{watchStatus ? (<div className=" capitalize space-x-1"> <EyeIconSvg color="white" /> <div>watching</div> </div>) : null}
            {/* <span onClick={toggleBetweenCalAndList} aria-hidden>
              {" "}
              {props.toggleValue ? (
                <button className="nav-btn capitalize w-28 flex justify-between space-x-1">
                  <div>List</div>{" "}
                  <div className="h-6 w-6 bg-white rounded"></div>
                </button>
              ) : (
                <button className="nav-btn w-28 capitalize flex space-x-1 justify-between">
                  {" "}
                  <div className="h-6 w-6 bg-white rounded"></div>{" "}
                  <div>{"Calendar"}</div>
                </button>
              )}
            </span> */}
            {/* <Read access={props.currentOpening.access}>
              <button
                onClick={props.showPopup}
                className="nav-btn capitalize"
                data-testid="clickOnslack"
              >
                {capitalizeFirstLetter(SLACK)}
              </button>
            </Read> */}
            {/* <Filter /> */}

          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  roles: state.opening.roles,
  notificationReduxState: state.opening.notification,
  companyBoard: state.opening.companyBoards,
  openings: state.opening.openings, // Assuming openings is your Redux state containing boards

})
const mapPropsTostate = (dispatch: Dispatch) => ({
  saveCurrentBoard: (board: Opening) => dispatch(initCurrentBoard(board)),
  removeCurrentBoard: () => dispatch(removeCurrentBoard()),

})
export default connect(mapStateToProps, mapPropsTostate)(SubNavbar)
