import {
  init_list,
  add_list,
  drag_list,
  edit_list_name,
  move_list,
  archive_list,
  clear_data,
  move_list_ws,
  init_archived_list,
  send_back_archived_list,
  edit_analytics_list_label_name,
} from "./list";
import {
  add_opening,
  init_opening,
  update_opening_name,
  init_current_opening,
  init_ws,
  update_board_background_color,
  starred_board,
  update_opening_description,
  init_opening_membership,
  remove_current_opening,
  slack_integration,
  show_search,
  set_slack_option,
  board_slack_options,
  delete_slack_option,
  update_search_flag,
  dequeue_ws_request_action,
  add_ws_request_action,
  reconnect_ws_request_action,
  filter,
  remove_filters,
  filter_count,
  sorting_board,
  edit_role_of_board,
  change_board_visibility,
  user_notification_settings_integration,
  add_board_to_workspace,
  add_opening_from_ws,
  init_current_tracker_jd,
  init_current_tracker_jd_form,
  // update_ws_request_status,
} from "./board";
import {
  add_applicant,
  add_attachments,
  attachments,
  attachments_loader,
  copy_applicant,
  drag_card,
  edit_applicant_name,
  init_current_applicant,
  move_applicant,
  move_applicants_of_list,
  init_archived_applicant,
  archived_applicants_of_list,
  update_archive_applicant_status,
  update_applicant_desc,
  remove_attachemnt,
  update_attachment,
  clear_card_data,
  move_applicant_another_board,
  copy_applicant_another_board,
  send_back_archived_item,
  copy_applicant_attachments,
  init_current_card_mails_preview,
  init_current_card_mail_thread,
  add_mail_in_current_thread_of_card,
  add_mail_on_current_card,
} from "./applicant";
import {
  change_filledFeedbackForm_state,
  reset_ws,
  set_client_id,
  topic_subscription,
} from "./web_socket";
import { save_comment, init_comments, update_comment } from "./comment";

import {
  init_labels,
  save_board_label,
  save_board_label_in_card,
  init_board_labels,
  save_card_label,
  update_label,
  remove_label_from_card,
  collapse_label,
} from "./label";
import {
  init_applicant_members,
  remove_member_from_card,
  add_member_on_card,
  init_members,
  add_member_on_board,
  remove_member_from_opening,
  update_member_details,
  add_creator_on_card,
  init_analytics_labels,
  add_org_member,
} from "./member";
import {
  login_user,
  logout_user,
  register_current_org,
  token_Exchange,
  update_user,
  email_Verify,
  org_setting_update,
  clear_tab_states,
  set_new_org_wsconnection,
  phone_Verify,
  update_user_services,
  org_details_update,
} from "./authorization";
import {
  init_activity,
  init_opening_activity,
  add_activity,
  update_activity,
  clear_board_activity,
} from "./activity";
import { add_chat_integration, add_current_org, change_loader_status, disconnect_slack_member, init_current_org, init_platform, integrate_member_to_slack, integrated_slack_members, show_org, update_chat_integration, update_current_org_creation_stages, update_org_Id_Name } from "./org"
import { add_incoming_invitaions, add_invitaions, init_incoming_invitaions, init_invitaions, withdraw_invitaions } from "./invitations";
import { has_some_error } from "./error";
import { initialState } from "./initialState";
import {
  add_CandidateDB_Table_header,
  add_Candidate_In_DB,
  init_candidate_in_review,
  init_current_candidate_in_review,
  init_candidate_labels,
  add_candidate_labels,
  add_member_on_CDB,
  init_CDB_member,
  init_CDBtable_head,
  init_candidates,
  edit_candidate,
  init_review_attachments,
  init_review_comments,
  add_review_comment,
  init_current_candidate_in_db,
  add_label_current_candidate_in_db,
  add_labels_to_candidate_in_review,
  add_review_attachment,
  init_current_candidate_labels,
  update_attachment_of_candidate,
  init_review_labels,
  add_member_on_review,
  remove_member_on_review,
  update_cdb_member_role,
  delete_cdb_member,
  add_labels_on_multiple_review,
  add_members_on_multiple_review,
  update_comment_of_review,
  add_reviews_labels,
  init_candidate_attachments,
  add_candidate_attachment,
  update_candidate_labels,
  update_review_labels,
  update_state_of_close_review,
  current_CDB_activity,
  current_CDB_ATS_activity,
  delete_attachment_of_candidate,
  remove_label_from_review,
  remove_label_from_candidate,
  current_CDB_review_activity,
  remove_label_from_reviews,
  add_candidate_to_review,
  init_mappings,
  update_mapping,
  init_sources,
  applicant_status,
} from "./candidateDB";
import {
  add_current_calendar,
  add_event_on_board_events,
  add_meet_access,
  add_new_event_to_card,
  add_zoom_access,
  delete_board_event,
  delete_card_event,
  edit_event_from_board,
  edit_event_from_card,
  remove_meet_access,
  remove_zoom_access,
  set_board_events,
  set_calender_id,
  set_card_events,
  set_currentUser_ZoomMeetAcc,
  set_current_calendar_conference_settings,
  update_current_calendar,
} from "./calender";
import {
  add_domain_of_org,
  add_mail_in_current_candidate_mail_thread,
  add_mail_in_mail_thread,
  change_domain_status,
  current_candidate_mails,
  current_candidate_mail_thread,
  current_mail_thread,
  mark_as_read_mail,
  user_mails_preview,
} from "./tacitmail";
import {
  add_notification_from_ws,
  change_board_entities_dot,
  change_service_dot_of_entities,
  change_service_notifications_count,
  init_all_notifications,
  init_current_board_watcher,
  init_current_card_watcher,
  init_dashboard_notifications,
  init_entity_unread_notifications,
  init_entity_unread_notifications_board,
  init_notification_center_unread,
  init_notifications_dot,
  init_notifications_dot_initial_state,
  init_notifications_initial_state,
  init_unread_notifications,
  remove_notification_dot_of_entity,

  remove_service_notification_dot,
  set_notification_option,
  show_dot_or_not_on_updates,
  unread_notification_dot_adder,
  unread_notification_dot_remover,
} from "./notification";
import { add_JD_reviewer, add_member_in_jd, init_JD_member, init_current_jd, remove_JD_member, update_JD_member_role, update_current_jd } from "./JD"
import { add_assignee_to_company, add_companies, add_member_on_company, current_company, edit_workspace_details, init_companies, init_company_member, remove_assignee_to_company, remove_company_member } from "./workspace"
import { add_Candidate_In_Pool, add_attachment_upload_status, add_label_to_pool_candidate_in_ats, add_labels_on_multiple_ats_pool_candidates, add_new_pool_candidate_attachment, add_pool_candidate_attachment, init_candidates_ats_pool, init_current_candidate_in_ats_pool, init_current_candidate_labels_in_ats_pool, remove_label_from_pool_candidate, remove_label_from_pool_candidate_in_ats } from "./atsPool";

function createReducer(initialState: any, handlers: any) {
  return function reducer(state = initialState, action: any) {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}

// Slice reducer
const rootReducer = createReducer(initialState, {
  INIT_WS: init_ws,
  RESET_WS: reset_ws,
  TOPIC_SUBSCRIPTION: topic_subscription,
  INIT_BOARDS: init_opening,
  ADD_OPENING: add_opening,
  ADD_OPENING_FROM_WS: add_opening_from_ws,
  INIT_BOARD_LABELS: init_board_labels,
  INIT_CURRUNT_BOARD: init_current_opening,
  INIT_CURRUNT_TRACKER_JD: init_current_tracker_jd,
  INIT_CURRUNT_TRACKER_JD_FORM: init_current_tracker_jd_form,
  CHANGE_BOARD_VISIBILITY: change_board_visibility,
  UPDATE_OPENING_NAME: update_opening_name,
  UPDATE_BOARD_BACKGROUND_COLOR: update_board_background_color,
  STARRED_BOARD: starred_board,
  UPDATE_OPENING_DESCRIPTION: update_opening_description,
  INIT_OPENING_MEMBERS: init_opening_membership,
  INIT_OPENING_ACTIVITY: init_opening_activity,
  CLEAR_OPENING_ACTIVITY: clear_board_activity,
  SORTING_BOARD: sorting_board,
  INIT_LISTS: init_list,
  ADD_LIST: add_list,
  MOVE_LIST: move_list,
  DRAG_LIST: drag_list,
  EDIT_LIST_NAME: edit_list_name,
  MOVE_LIST_WS: move_list_ws,
  INIT_ARCHIVE_LISTS: init_archived_list,
  SEND_BACK_ARCHIVED_LIST: send_back_archived_list,

  ADD_APPLICANT: add_applicant,
  INIT_CURRENT_APPLICANT: init_current_applicant,
  COPY_APPLICANT: copy_applicant,
  DRAG_CARD: drag_card,
  // DRAG_CARD: drag_card,
  MOVE_APPLICANTS_OF_LIST: move_applicants_of_list,
  MOVE_APPLICANT: move_applicant,
  EDIT_APPLICANT_NAME: edit_applicant_name,
  UPDATE_APPLICANT_DESC: update_applicant_desc,
  INIT_CURRENT_APPLICANT_MAILS_PREVIEW: init_current_card_mails_preview,
  INIT_CURRENT_APPLICANT_MAIL_THREAD: init_current_card_mail_thread,
  ADD_MAIL_IN_CURRENT_THREAD_OF_CARD: add_mail_in_current_thread_of_card,
  ADD_MAIL_ON_CURRENT_CARD: add_mail_on_current_card,
  // current org
  ADD_CURRENT_ORG:add_current_org,
  UPDATE_CURRENT_ORG_CREATION_STAGES:update_current_org_creation_stages,
  INIT_CURRENT_ORG:init_current_org,
  INIT_JOB_PLATFORM: init_platform,

  ATTACHMENTS_LOADER: attachments_loader,
  ATTACHMENTS: attachments,
  ADD_ATTACHMENTS: add_attachments,
  DELETE_ATTACHMENT: remove_attachemnt,
  UPDATE_ATTACHMENT: update_attachment,
  COPY_ATTACHMENTS: copy_applicant_attachments,

  UPDATE_COMMENT: update_comment,
  INIT_COMMENTS: init_comments,
  SAVE_COMMENT: save_comment,

  INIT_APPLICANT_ACTIVITY: init_activity,

  UPDATE_LABEL: update_label,
  COLLAPSE_LABEL: collapse_label,
  REMOVE_LABEL_FROM_CARD: remove_label_from_card,
  SAVE_CARD_LABEL: save_card_label,
  SAVE_BOARD_LABEL_IN_CARD: save_board_label_in_card,
  SAVE_BOARD_LABEL: save_board_label,
  INIT_LABELS: init_labels,
  SEND_BACK_ARCHIVED_ITEM: send_back_archived_item,
  ARCHIVED_APPLICANTS_OF_LIST: archived_applicants_of_list,
  INIT_ARCHIVED_APPLICANT: init_archived_applicant,
  UPDATE_ARCHIVE_STATUS: update_archive_applicant_status,
  INIT_MEMBERS: init_members,
  INIT_APPLICANT_MEMBERS: init_applicant_members,
  ADD_MEMBER_ON_CARD: add_member_on_card,
  ADD_CREATOR_ON_CARD: add_creator_on_card,
  ADD_MEMBER_ON_BOARD: add_member_on_board,
  REMOVE_MEMBER_FROM_CARD: remove_member_from_card,
  SET_CLIENT_ID: set_client_id,
  SET_CALENDER_ID: set_calender_id,
  SET_CARD_CALENDAR_EVENTS: set_card_events,
  UPDATE_CARD_CALENDAR_EVENTS: add_new_event_to_card,
  DELETE_CARD_EVENT: delete_card_event,
  SET_CURRENT_CALENDAR_CONFERENCE_SETTINGS:
    set_current_calendar_conference_settings,
  EDIT_CARD_EVENT: edit_event_from_card,
  SET_CURRENT_CAL: add_current_calendar,
  UPDATE_CURRENT_CALENDAR: update_current_calendar,
  SET_CURRENT_USER_ZOOM_MEET_ACCOUNT: set_currentUser_ZoomMeetAcc,
  REMOVE_MEET_ACCESS: remove_meet_access,
  REMOVE_ZOOM_ACCESS: remove_zoom_access,
  ADD_ZOOM_ACCESS: add_zoom_access,
  ADD_MEET_ACCESS: add_meet_access,

  DELETE_BOARD_EVENT: delete_board_event,
  EDIT_BOARD_EVENT: edit_event_from_board,
  SET_BOARD_CAL_EVENTS: set_board_events,
  ADD_EVENT_ON_BOARD_CALENDER: add_event_on_board_events,
  LOGIN_USER: login_user,
  LOGOUT_USER: logout_user,
  UPDATE_USER: update_user,
  MOVE_APPLICANT_TO_ANOTHER_OPENING: move_applicant_another_board,
  COPY_APPLICANT_TO_ANOTHER_OPENING: copy_applicant_another_board,

  ORG_TOKEN: token_Exchange,
  REMOVE_MEMBER_FROM_OPENING: remove_member_from_opening,
  ARCHIVE_LIST: archive_list,
  REMOVE_CURRUNT_OPENING: remove_current_opening,
  REGISTER_CURRENT_ORG: register_current_org,
  SLACK_INTEGRATION: slack_integration,
  INTEGRATED_SLACK_MEMBER: integrated_slack_members,
  INTEGRATE_SLACK_MEMBER: integrate_member_to_slack,
  DISCONNECT_SLACK_ACCOUNT_OF_MEMBER: disconnect_slack_member,

  BOARD_SLACK_OPTIONS: board_slack_options,
  SHOW_ORG: show_org,
  INIT_OUTGOING_INVIATIONS: init_invitaions,
  INIT_INCOMING_INVIATIONS: init_incoming_invitaions,
  ADD_INVITATION: add_invitaions,
  WITHDRAW_INVITATION: withdraw_invitaions,

  ADD_INCOMING_INVITATION: add_incoming_invitaions,
  CLEAR_DATA: clear_data,
  CLEAR_CARD_DATA: clear_card_data,
  SHOW_SEARCH: show_search,
  SET_ERROR: has_some_error,
  SET_SLACK_OPTIONS: set_slack_option,

  // notification
  DOT_INITIAL_STATE:init_notifications_dot_initial_state,
  UNREAD_NOTIFICATION_DOT_REMOVER:unread_notification_dot_remover,
  SHOW_DOT_OR_NOT_ON_UPDATES:show_dot_or_not_on_updates,
  UNREAD_NOTIFICATION_DOT_ADDER:unread_notification_dot_adder,
  INIT_NOTIFICATION_CENTER_UNREAD:init_notification_center_unread,
  ADD_NOTIFICATION_FROM_WS:add_notification_from_ws,
  SET_NOTIFICATION_OPTIONS: set_notification_option,
  INIT_NOTIFICATIONS_DOT:init_notifications_dot,
  CHANGE_SERVICE_NOTIFICATIONS_COUNT:change_service_notifications_count,
  REMOVE_SERVICE_NOTIFICATION_DOT:remove_service_notification_dot,
  USER_NOTIFICATION_SETTINGS_INTEGRATION:
  user_notification_settings_integration,
  change_service_dot_of_entities:change_service_dot_of_entities,
  INIT_ENTITY_UNREAD_NOTIFICATIONS:init_entity_unread_notifications,
  INIT_ALL_NOTIFICATIONS: init_all_notifications,
  INIT_UNREAD_NOTIFICATIONS: init_unread_notifications,
  INIT_DASHBOARD_NOTIFICATIONS: init_dashboard_notifications,
  INIT_NOTIFICATIONS_INITIAL_STATE:init_notifications_initial_state,
  CHANGE_BOARD_ENTITIES_DOT:change_board_entities_dot,
  REMOVE_NOTIFICATION_DOT_OF_ENTITY:remove_notification_dot_of_entity,

    INIT_CURRENT_CARD_WATCHER:init_current_card_watcher,
    INIT_CURRENT_BOARD_WATCHER:init_current_board_watcher,
    INIT_ENTITY_UNREAD_NOTIFICATIONS_BOARD:init_entity_unread_notifications_board,



  RESET_SLACK_OPTIONS: delete_slack_option,
  ADD_ACTIVITY: add_activity,
  EMAIL_VERIFY: email_Verify,

  UPDATE_SEARCH_FLAG: update_search_flag,

  CHANGE_FILLEDFEEDBACKFORM_STATE: change_filledFeedbackForm_state,

  PHONE_VERIFY: phone_Verify,

  ADD_WS_REQUEST_ACTION: add_ws_request_action,
  DEQUEUE_WS_REQUEST_ACTION: dequeue_ws_request_action,
  RECONNECTION_WS_REQUEST: reconnect_ws_request_action,
  SET_NEW_WS_CONNECTION: set_new_org_wsconnection,
  UPDATE_ACTIVITY: update_activity,
  ORG_SETTING_UPDATE: org_setting_update,
  CLEAR_TAB_DATA: clear_tab_states,
  FILTER: filter,
  REMOVE_FILTERS: remove_filters,
  FILTER_COUNT: filter_count,
  INIT_MAPPINGS: init_mappings,
  UPDATE_MAPPING: update_mapping,

  UPDATE_MEMBER: update_member_details,
  UPDATE_ORG_INFO: update_org_Id_Name,
  EDIT_BOARD_ROLE: edit_role_of_board,


  /**************************************** CDB *******************************************/
  ADD_CANDIDATE_IN_DB: add_Candidate_In_DB,
  ADD_CDB_TABLE_HEADER: add_CandidateDB_Table_header,
  INIT_CDBTABLE_HEAD: init_CDBtable_head,
  INIT_CANDIDATE_LABEL: init_candidate_labels,
  ADD_CANDIDATE_LABEL: add_candidate_labels,
  ADD_MEMBER_ON_CDB: add_member_on_CDB,
  INIT_CDB_MEMBER: init_CDB_member,
  INIT_CANDIDATES: init_candidates,
  EDIT_CANDIDATE: edit_candidate,
  INIT_CANDIDATE_ATTACHMENTS: init_candidate_attachments,
  UPDATE_CANDIDATE_LABEL: update_candidate_labels,
  CHANGE_STATE_OF_CLOSE_REVIEW: update_state_of_close_review,
  CURRENT_CDB_ACTIVITY: current_CDB_activity,
  ATS_ACTIVITY_OF_CANDIDATE: current_CDB_ATS_activity,
  DELETE_CANDIDATE_ATTACHMENT: delete_attachment_of_candidate,
  REMOVE_LABEL_FROM_CANDIDATE: remove_label_from_candidate,
  INIT_CURRENT_CANDIDATE_IN_DB: init_current_candidate_in_db,
  UPDATE_ATTACHMENT_OF_CANDIDATE: update_attachment_of_candidate,
  ADD_LABEL_TO_CUURENT_CANDIDATE_IN_CDB: add_label_current_candidate_in_db,
  ADD_CANDIDATE_ATTACHMENT: add_candidate_attachment,
  INIT_CURRENT_CANDIDATE_LABELS: init_current_candidate_labels,
  UPDATE_CDB_MEMBER_ROLE: update_cdb_member_role,
  DELETE_CDB_MEMBER: delete_cdb_member,
  CURRENT_CANDIDATE_MAIL_THREAD: current_candidate_mail_thread,
  CURRENT_CANDIDATE_MAILS: current_candidate_mails,
  ADD_MAIL_IN_CANDIDATE_MAIL_THREAD: add_mail_in_current_candidate_mail_thread,



  /*********************************************JD *********************** */
  USER_MAILS: user_mails_preview,
  CURRENT_SELECTED_MAIL: current_mail_thread,
  ADD_DOMAIN_OF_ORG: add_domain_of_org,
  ADD_MAIL_IN_INBOX_THREAD: add_mail_in_mail_thread,
  MARK_AS_READ_MAIL: mark_as_read_mail,
  CHANGE_DOMAIN_STATUS: change_domain_status,



  //JD
  INIT_JD_MEMBER: init_JD_member,
  ADD_MEMBER_ON_JD: add_member_in_jd,
  REMOVE_MEMBER_ON_JD: remove_JD_member,
  UPDATE_JD_MEMBER_ROLE: update_JD_member_role,
  INIT_CURRENT_JD: init_current_jd,
  UPDATE_CURRENT_JD: update_current_jd,
  ADD_JD_REVIEWER: add_JD_reviewer,


  //company
  INIT_COMPANIES: init_companies,
  ADD_COMPANY: add_companies,
  CURRENT_COMPANY: current_company,
  ADD_MEMBER_ON_COMPANY: add_member_on_company,
  INIT_COMPANY_MEMBER: init_company_member,
  ADD_ASSIGNEE_TO_COMPANY: add_assignee_to_company,
  REMOVE_COMPANY_MEMBER: remove_assignee_to_company,
  ADD_BOARD_TO_WORKSPACE: add_board_to_workspace,
  EDIT_WORKSPACE_DETAILS: edit_workspace_details,
  REMOVE_MEMBER_FROM_WORKSPACE: remove_company_member,

  CHANGE_LOADER_STATUS:change_loader_status,
  ADD_CHAT_INTEGRATION:add_chat_integration,
  UPDATE_CHAT_INTEGRATION:update_chat_integration,
  ADD_ORG_MEMBER:add_org_member,
  UPDATE_USER_SERVICES:update_user_services,



  /************************************* Review ******************************************************/
  ADD_LABEL_ON_MULTIPLE_REVIEWS: add_labels_on_multiple_review,
  INIT_CANDIDATES_IN_REVIEW: init_candidate_in_review,
  INIT_CURRENT_CANDIDATE_IN_REVIEW: init_current_candidate_in_review,
  ADD_MEMBERS_ON_MULTIPLE_REVIEWS: add_members_on_multiple_review,
  UPDATE_COMMENT_OF_REVIEW: update_comment_of_review,
  INIT_REVIEW_LABELS: init_review_labels,
  ADD_LABEL_TO_CANDIDATE_IN_REVIEW: add_labels_to_candidate_in_review,
  ADD_REVIEW_LABEL: add_reviews_labels,
  ADD_REVIEW_ATTACHMENT: add_review_attachment,
  INIT_REVIEW_COMMENTS: init_review_comments,
  ADD_REVIEW_COMMENT: add_review_comment,
  ADD_CANDIDATE_IN_REVIEW: add_candidate_to_review,
  REMOVE_MEMBER_ON_REVIEW: remove_member_on_review,
  CDB_REVIEW_ACTIVITY: current_CDB_review_activity,
  REMOVE_LABEL_FROM_REVIEWS: remove_label_from_reviews,
  REMOVE_LABEL_FROM_REVIEW: remove_label_from_review,
  ADD_MEMBER_ON_REVIEW: add_member_on_review,
  INIT_REVIEW_ATTACHMENTS: init_review_attachments,
  UPDATE_REVIEW_LABEL: update_review_labels,

  

  UPDATE_ORG_SETTING_DETAILS:org_details_update,

  // update analytics label 

  UPDATE_ANALYTICS_LIST_LABEL_NAME:edit_analytics_list_label_name,

  //store analytics label

  INIT_ANALYTICS_LABELS:init_analytics_labels,


  //  Ats pool
  INIT_CURRENT_CANDIDATE_IN_ATS_POOL:init_current_candidate_in_ats_pool,
  INIT_CANDIDATES_ATS_POOL:init_candidates_ats_pool,
  INIT_CURRENT_CANDIDATE_LABELS_IN_ATS_POOL:init_current_candidate_labels_in_ats_pool,
  ADD_LABEL_TO_POOL_CANDIDATE_IN_ATS: add_label_to_pool_candidate_in_ats,
  REMOVE_LABEL_FROM_POOL_CANDIDATE_IN_ATS: remove_label_from_pool_candidate_in_ats,
  ADD_POOL_CANDIDATE_ATTACHMENT: add_pool_candidate_attachment,
  ADD_ATTACHMENT_UPLOAD_STATUS: add_attachment_upload_status,
  ADD_NEW_POOL_CANDIDATE_ATTACHMENT:add_new_pool_candidate_attachment,
  REMOVE_LABEL_FROM_POOL_CANDIDATE:remove_label_from_pool_candidate,
  ADD_LABELS_ON_MULTIPLE_ATS_POOL_CANDIDATES:add_labels_on_multiple_ats_pool_candidates,
  ADD_CANDIDATE_IN_POOL: add_Candidate_In_Pool,
  INIT_SOURCES: init_sources,
  APPLICANT_STATUS_FOR_TRACKER: applicant_status
});
export default rootReducer
export type RootState = ReturnType<typeof rootReducer>
