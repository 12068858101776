import React from "react"
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter"
import { Typography } from "antd";
const {  Link } = Typography;

const Footer = () => {
    const d = new Date()
    let year = d.getFullYear()
    return (
        <>
            <div className=" md:flex   lg:justify-between border-t mt-2 items-center lg:items-center px-4 text-sm font-medium py-2" style={{background: "rgb(255, 249, 247)"}}>
                <div className="flex justify-center w-full">
          <span>{capitalizeFirstLetter("Powered by")} <Link target="_blank" href="https://www.tacitbase.com/"  className="font-semibold text-sm" style={{color:"#F26046"}}>Tacitbase</Link></span>
        </div>
            </div>
        </>
    )
}
export default Footer
