import { jwtDecode } from "jwt-decode";
import { logoutFromAllTabs } from "../utils/logoutFromAllTabs";
export const tokenVerify = () => {
  let currentDate = new Date();
  let decodedToken: any = {};
  const token = localStorage.getItem("login_token");
  if (token) {
    decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      return false; //token expire
    } else {
      return true; //valid
    }
  } else {
    return false;
  }
};
export const orgtokenVerify = () => {
  let currentDate = new Date();
  let decodedToken: any = {};
  const token = sessionStorage.getItem("current_tab_application_token");
  if (token) {
    decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      return false; //token expire
    } else {
      return true; //valid
    }
  } else {
    return false;
  }
};

window.addEventListener("visibilitychange", checkValidToken);

function checkValidToken(){
  let token = localStorage.getItem("login_token")
  if(token && !tokenVerify()){
    logoutFromAllTabs()
    window.removeEventListener("visibilitychange", checkValidToken);
  }
} 
