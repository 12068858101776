import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Dispatch } from "redux";
import { createOpeningMembership } from "../../../lib/api";
import { AddMemberReq } from "../../../lib/apiReq";
import { addMemberOnBoard } from "../../../redux/actions/opening/action";

import { loginUser, Member, Opening, OpeningMembers } from "../../../type/type";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { SOCKET_CLIENT_ID } from "../../../constant";
import { orgMemberRoleVisibility } from "../../../type/type";

type JoinBoardProps = {
  currentOpening: Opening;
  loginUser: loginUser;
  clientId: string;
  openingMembers: OpeningMembers;
  applicationId: string;
  addMember(members: Member[], OpeningID: string): void;
};

const JoinBoard = (props: JoinBoardProps) => {
  const [memberwithVisibility,setMemberwithVisibility] =useState<orgMemberRoleVisibility[]>([]);
  const JoinBoardAsCommentor = () => {
    // let ids = [props.loginUser.user.id];
    const newMemberVisibility = { id: props.loginUser.user.id,role:"Commentor",    
      is_secrete_visible: false,
  };
    setMemberwithVisibility([newMemberVisibility])
    let clientId=sessionStorage.getItem(SOCKET_CLIENT_ID)

    const req: AddMemberReq = {
      id: props.currentOpening.id,
      members: memberwithVisibility,
      role: "Commentor",
      is_secrete_visible: false,
      socket_client_id:clientId?clientId:"",
    };
    createOpeningMembership(req)
      .then((res: Member[]) => {
        props.addMember(res, props.currentOpening.id);
      })
     
  };
  const history = useHistory();

  const goToHompage = () => {
    history.push({
      pathname: `/o/${props.applicationId}/homepage`,
    });
  };

  return (
    <div className="flex px-2 flex-col items-center rounded bg-gray-100 p-2 z-20">
      <div className="px-2 py-0.5 text-sm mb-1 text-gray-600">
        You are viewing{" "}
        <span className="font-semibold">{props.currentOpening.name}</span>{" "}
        board.<br></br> If you join, you will be added as a commentor on this
        public board.
      </div>
      <div className="flex justify-end w-full">
        <button
          onClick={goToHompage}
          className="bg-red-700 text-white text-sm active:ring-red-900  active:ring-2   active:bg-gray-10  rounded tracking-wider m-1 py-1 px-2 focus:outline-none outline-none "
          data-testid="clickOnslack"
        >
          {capitalizeFirstLetter("back")}
        </button>{" "}
        <button
          onClick={JoinBoardAsCommentor}
          className="submit-btn z-20 text-sm capitalize"
          data-testid="clickOnslack"
        >
          {capitalizeFirstLetter("join")}
        </button>{" "}
      </div>
    </div>
  );
};
const mapPropsToState = (dispatch: Dispatch) => ({
  addMember: (members: Member[], OpeningID: string) =>
    dispatch(addMemberOnBoard(members, OpeningID)),
});
const mapStateToProps = (state: any) => ({
  openingMembers: state.opening.openingMembers,
  currentOpening: state.opening.currentOpening,
  loginUser: state.opening.loginUser,
  clientId: state.opening.clientId,
  applicationId: state.opening.applicationId,
});
export default connect(mapStateToProps, mapPropsToState)(JoinBoard);
