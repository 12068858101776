import React, { useEffect, useState } from "react";
import {  Space } from "antd";
import { getApplicantStatus ,getCandidates} from "../../../../../lib/api";
import { Candidate, FormBuilder, Opening } from "../../../../../type/type";
import TacitbaseAvtar from "../../../../../ant/Avtar";
import {  NOT_AVAILABLE } from "../../../../../constant";
import { connect } from "react-redux";
import { IconButton } from "../../../../../ant/Button";
import { ReloadOutlined } from "@ant-design/icons"
import { useHistory } from "react-router-dom";
import ApplicantDetailView from "./ApplicantDetailView";
import { Dispatch } from "redux";
import { applicantStatus, initCandidatesAtsPool }  from "../../../../../redux/actions/opening/action";
import { renderCreatedAt } from "../../../../../utils/renderCreatedAt";
import { capitalizeFirstLetter } from "../../../../../utils/capitalizeFirstLetter";
import TableAnt from "../../../../../ant/TableAnt"
import QuickAddCandidate from "../../../../addCandidates/QuickAddCandidate";

// Type definition for the Redux props
type TableReduxProps = {
  currentOpening: Opening;
  applicationId: string;
  initCandidates: (members: Candidate[], currentPage: string) => void;
  candidatePool: any;
  currentTrackerForm: FormBuilder;
  currentTrackerJd:any;
  sources:{[key:string]:{name:string,profile:string}},
  applicantStatus: (applicants: { id: string, trackers: string[] }[]) => void,
  applicantionStatus: {
    [key: string]: Candidate[]
  }
};



const PoolTable = ({
  currentOpening,
  applicationId,
  initCandidates,
  candidatePool,
  currentTrackerJd,
  sources,
  applicantStatus,
  applicantionStatus
}: TableReduxProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [selectedRowKey, setSelectedRowKey] = useState<string>("");
  const [tableParams, setTableParams] = useState<any>({
    pagination: {
      current: 1,
      pageSize: 25,
      position: ["bottomLeft"],
      size: "default",
      showSizeChanger: false,
      style: {
        marginTop: "0px",
        borderTop: "1px solid rgba(5, 5, 5, 0.06)",
        paddingTop: "9px",
      },
    },
  });

  const history = useHistory();

  useEffect(() => {
    // Dynamically set the height of the table body
    const tableBody: any = document.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.style.height = "calc(100vh - 330.8px)";
    }
  }, [data]);

  useEffect(() => {
    // Populate data from candidate pool when available
    if (candidatePool) {
      setData(generateData(Object.values(candidatePool)));
    } else {
      setData([]);
    }
  }, [candidatePool]);

  useEffect(() => {
    // Fetch data when table parameters or current tracker form changes
    if (tableParams.pagination.current && currentTrackerJd.id) {
      fetchData({ currentPage: tableParams.pagination.current });
    }
  }, [currentTrackerJd.id]);

  // Generate data for the table from candidate pool
  const generateData = (result: any) => {
    return result.map((candidate: Candidate) => ({
      key: candidate.id,
      id: candidate.id,
      phone: candidate.phone,
      source: candidate.source.from,
      email: candidate.email,
      candidates: {
        first_name: candidate.first_name,
        last_name: candidate.last_name,
      },
      created_by: candidate.created_by,
      created_at: candidate.created_at,
      comment_count: candidate.comment_count,
      attachment_count: candidate.attachment_count,
    }));
  };

  // Fetch data from the API
  const fetchData = (reqParams: { currentPage: number }) => {
    // if (!currentTrackerJd.id) return 
    setLoading(true);
    
    getCandidates(reqParams.currentPage,25,currentTrackerJd.id)
      .then((res) => {

        initCandidates(res.items, reqParams.currentPage.toString());
        setTableParams((prev: any) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            total: res.total_count,
          },
        }));
        getApplicantStatus({ "ids": res.items.map((items) => items.id) }).then((res: any) => {
          applicantStatus(res)
        })
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
// Column definitions for the Ant Design table
const columns = [
  {
    title: "Name",
    dataIndex: "candidates",
    ellipsis: true,
    width: "25%",
    showSorterTooltip: false,
    sorter: (a: any, b: any) =>
      a.candidates?.first_name?.localeCompare(b.candidates?.first_name),
    render: (_: any, record: any) => (
      <Space className="truncate w-full">
        <TacitbaseAvtar content={record.candidates.first_name[0]} />
        <div className="truncate">{`${capitalizeFirstLetter(record.candidates.first_name)} ${capitalizeFirstLetter(record.candidates.last_name)}`}</div>
      </Space>
    ),
  },
  {
    width: "25%",
    title: "Email",
    ellipsis: true,
    dataIndex: "email",
  },
  {
    width: "15%",
    title: "Phone",
    dataIndex: "phone",
    ellipsis: true,
  },
  {
    width: "20%",
    title: "Source",
    dataIndex: "source",
    ellipsis: true,
    render: (sourceKey: string) => {
    const sourceName = sources[sourceKey] ? capitalizeFirstLetter(sources[sourceKey].name) : capitalizeFirstLetter(sourceKey);
    const sourceImage = sources[sourceKey]?.profile; // Assuming 'image' is a property in your source data
    return sourceKey.trim().length === 0 ? (
      NOT_AVAILABLE
    ) : (
        <Space className="truncate w-full">
          {sourceImage ? (
            <img src={sourceImage} alt={sourceName} style={{ width: 20, height: 20, borderRadius: '50%' }} />
          ) : (
            <TacitbaseAvtar content={sourceName[0]} />
          )}
          <div className="truncate">{sourceName}</div>
        </Space>
      );
    },
  },
  {
    width: "14%",
    title: "Created at",
    dataIndex: "created_at",
    ellipsis: true,
    render: (record: any) => <span>{renderCreatedAt(record)}</span>,
  },
];
  // Handle row selection changes
  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  // Handle table changes
  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setTableParams({
      pagination,
      filters,
      sorter,
    });
    fetchData({ currentPage: pagination.current });
  };

  // Handle row click event
  const onRowClick = (record: any) => {
    const candidateId = record.key;
    const url = `/o/${applicationId}/b/${currentOpening.id}/pool/${candidateId}`;
    setSelectedRowKey(candidateId);
    history.push(url);
  };
  

  // Handle row hover event
  const handleRowHover = (record: any) => {
    setHoveredRow(record ? record.key : null);
  };

  const handleRefreshTab = () => {
    fetchData({
      currentPage: tableParams.pagination.current,
    });
  };

  return (
    <div className="flex flex-col px-4 py-3">
      <div className="bg-white flex flex-row w-full h-12 items-center rounded-t">
          <div className="flex flex-1 flex-row px-4 justify-between ">
            <div className="flex  justify-start">
                <div className="flex flex-row space-x-2">
                  <QuickAddCandidate />
                </div>
            </div>
            <div className="flex  justify-end ">
              <IconButton
                icon={<ReloadOutlined />}
                onClick={handleRefreshTab}
                type="default" className="h-7 "
              />
            </div>
          </div>
         
      </div>
    <div className=" h-full z-0"        
    style={{ height: "calc(100vh - 240px)" }}
    >
      <div className="h-full">
      <TableAnt
        rowClassName={(record) =>
          record.key === hoveredRow ? "cursor-pointer" : ""
        }
        size="small"
        loading={loading}
        columns={columns}
        data={data}
          className="h-full w-full bg-white rounded-b  overflow-y-hidden "
        pagination={tableParams.pagination}
        onChange={handleTableChange}
        scroll={{ y: "calc(100vh - 130.8px)" }}
        onRow={(record) => ({
          onMouseEnter: () => handleRowHover(record),
          onMouseLeave: () => handleRowHover(null),
          onClick: () => onRowClick(record),
        })}
        
      />
      </div>
      <ApplicantDetailView selectedRow={selectedRowKey} />
    </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  initCandidates: (candidates: Candidate[], currentPage: string) =>
    dispatch(initCandidatesAtsPool(candidates, currentPage)),
  applicantStatus: (applicants: { id: string, trackers: string[] }[]) =>
    dispatch(applicantStatus(applicants)),
});

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  candidatePool: state.opening.candidatePool,
  currentTrackerForm: state.opening.currentTrackerForm,
  applicationId: state.opening.applicationId,
  currentTrackerJd: state.opening.currentTrackerJd,
  sources: state.opening.candidateSources,
  applicantionStatus: state.opening.applicantStatus,

});

export default connect(mapStateToProps, mapDispatchToProps)(PoolTable);
