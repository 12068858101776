import {
  ProjectOutlined

  // NotificationOutlined,
} from '@ant-design/icons'
import { Menu } from 'antd'
import { useHistory } from 'react-router-dom'
// import jwtDecode from "jwt-decode";
import React, { useState } from 'react'
import { connect } from 'react-redux'

import { addWsRequest, updateOrgNameId } from '../../redux/actions/opening/action'
import { loginUser } from '../../type/type.d'
import { servicesMembership } from '../../redux/reducers/initialState'
import { Dispatch } from 'redux'
import { SIDENAV_MENU_ITEMS } from '../../constant'
export type MenuItem = {
  label: string
  icon: React.ReactNode
  key: string
  access: boolean
  showBadge?: boolean
}

type SideNavProps = {
  applicationId: string
  applicationName: string
  roles: string[]
  clientId: string
  loginUser: loginUser
  UpdateOrgNameId(orgData: any): void
  addWsRequest(actionRequest: any): void
  servicesMembership: servicesMembership
  showUpdatesBadge: boolean
  updatesBadgeCount: number
  showDot: boolean
}

function SideMenu(props: SideNavProps) {
  // const location = useLocation()
  const [selectedKeys, setSelectedKeys] = useState('/')
  const history = useHistory()

  const menuItems: MenuItem[] = [
    {
      label: SIDENAV_MENU_ITEMS.BOARDS,
      icon: <ProjectOutlined />,
      key: `/o/${props.applicationId}/homepage`,
      access: false
    }
  ]

  return (
    <>
      <Menu
        style={{
          height: '100%'
        }}
        mode='inline' defaultActiveFirst
        onClick={(item) => {
          history.push({
            pathname: item.key
          })
          setSelectedKeys(item.key)
        }}
        selectedKeys={[selectedKeys]}
      >
        {menuItems.map((menuItem) => (
          <Menu.Item
            key={menuItem.key}
            icon={menuItem.icon}
            title={<span>{menuItem.label}</span>} // Set title for tooltip
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                placeItems: 'center',
                alignItems: 'center'
              }}
            >
              {menuItem.label}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    </>
  )
}

const mapPropsTostate = (dispatch: Dispatch) => ({
  UpdateOrgNameId: (orgData: any) => dispatch(updateOrgNameId(orgData)),
  addWsRequest: (actionRequest: any) => dispatch(addWsRequest(actionRequest))
})

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  applicationName: state.opening.applicationName,
  roles: state.opening.roles,
  loginUser: state.opening.loginUser,
  clientId: state.opening.clientId,
  servicesMembership: state.opening.servicesMembership,
  showUpdatesBadge: state.opening.showUpdatesBadge, // Modify based on your Redux state
  updatesBadgeCount: state.opening.updatesBadgeCount, // Modify based on your Redux state
  showDot: state.opening.notificationCenter.showDotOnUpdates
})

export default connect(mapStateToProps, mapPropsTostate)(SideMenu)
