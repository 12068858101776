import React, { useEffect, useState } from "react"
import {  Menu, Space } from "antd"
// import TacitbaseAvtar from "../../ant/Avtar"
import { connect } from "react-redux"
import { loginUser, orgMember } from "../../type/type"
import { useHistory, useLocation } from "react-router-dom";
import { capitalizeFirstLetter, generateTooltipForMember } from "../../utils/capitalizeFirstLetter"
import TacitbaseAvtar from "../../ant/Avtar"
type MenuItem = {
    key: string,
    icon: React.ReactNode,
    label: React.ReactNode,
    showBadge?: boolean
}
const SideMenu = (props: { members: orgMember; loginUser: loginUser; isCollaps: boolean; applicationId: string,showDot: boolean }) => {
    const location = useLocation()

    const profileMenu: MenuItem[] = [
        {
            key: "2",
            icon: <Space>
              <TacitbaseAvtar content={props.loginUser.user.firstName[0] + props.loginUser.user.lastName[0]}/>
            </Space>,
            label: (
                <>
                    <strong>
                        {capitalizeFirstLetter(generateTooltipForMember({
                            first_name: props.loginUser.user?.firstName,
                            last_name: props.loginUser.user?.lastName
                        }))}
                    </strong>
                </>
            ),
        },
    ];


    return (
        <>
          

            <Menu theme="light" mode="inline" selectedKeys={[]}>
                {profileMenu.map((menuItem: MenuItem) => (
                    <Menu.Item
                        style={{ padding: "0px 10px 0px 16px", paddingLeft: "0px", paddingRight: "0px", height: "43px", }}
                        key={menuItem.key}
                        icon={menuItem.icon}
                    >
                        {menuItem.label}

                    </Menu.Item>
                ))}
            </Menu>
        </>
    )
};

const mapStateToProps = (state: any) => ({
    loginUser: state.opening.loginUser,
    members: state.opening.members,
    openings: state.opening.openings,
    applicationId: state.opening.applicationId,
    showDot: state.opening.notificationCenter.showDotOnUpdates,
});

export default connect(mapStateToProps)(SideMenu)
