import axios, {  AxiosInstance } from "axios";
import throwError from "../service/throwError";
import {
  OpeningRes,
  getOpeningRes,
  createOpeningMembershipRes,  
  getOpeningMembersRes,
  getBoardLabelRes,
  registrationRes,
  Register,
  JWTtokenExchangeRes,
  getOrgMemberRes,
  getCandidatesRes,
  getCandidateRes,
  uploadAttachmentOfCandidateRes,
  PaginationCandidates,
  SourcesResponse,
  Source,
  guestLoginRes,
  Login,
  generateSecureLinkRes,
  Secret,
  getApplicantAttachmentsRes,
} from "./apiRes";
import {
  atsUploadHeader,
  authHeader1,
  authHeader,
} from "../service/authHeaders";
import {
  Label,
  Member,
  Opening,
  Candidate,
  Attachment,
  paginationDetails,
  FormBuilder,
  Jd,
  User,
} from "../type/type";
import {
  AddMemberReq,
} from "./apiReq";
const epBoard = "/v1/ats/agency/openings/";
const epMembers = "/v1/ats/agency/members/";
const epFusion = "/v1/orgs/auth/user/";
const epCDB = "/v1/db/";
const epJD = "/v1/jd/";
let api: AxiosInstance;

export const configureAPI = (baseURL: string | undefined) => {
  api = axios.create({ baseURL: baseURL });
};
/**************************************** Ats api *********************************************************/
//openings
export function getOpenings() {
  const ep = epBoard;
  
  return new Promise<Opening[]>((resolve, reject) => {
    api
      .get<getOpeningRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.openings);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
}


export const getBoardByid = (id: string) => {
  const ep = epBoard + id;
  return new Promise<Opening>((resolve, reject) => {
    api
      .get<OpeningRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200 && res.data.opening) {
          resolve(res.data.opening);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const createOpeningMembership = (req: AddMemberReq) => {
  const ep = epMembers + "openingMembership/" + req.id;
  return new Promise<Member[]>((resolve, reject) => {
    api
      .post<createOpeningMembershipRes>(ep, req, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200 && res.data.members) {
          resolve(res.data.members);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getOpeningMembers = (openingID: string, applicationId: string) => {
  const ep = `${epMembers}${openingID}/${applicationId}/openingMembers`;
  return new Promise<Member[]>((resolve, reject) => {
    api
      .get<getOpeningMembersRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          res.data.members = res.data.members ? res.data.members : [];
          resolve(res.data.members);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};


export const setTokenInLocalStorage = (
  tokenIdentifire: string,
  token: string
) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      localStorage.setItem(tokenIdentifire, token);
      resolve(true);     
    } catch {
      reject(false);
    }
  });
};

export const setTokenInSessionStorage = (token: string) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      sessionStorage.setItem("current_tab_application_token", token);
      resolve(true);
    } catch {
      reject(false);
    }
  });
};
export const setAnyTokenInSessionStorage = (key: string,value:string) => {
  return new Promise<boolean>((resolve, reject) => {
    try {
      sessionStorage.setItem(key, value);
      resolve(true);
    } catch {
      reject(false);
    }
  });
};


/********************************* fusion auth  ******************************/

//login
export const login = (loginObject: any) => {
  const ep = epFusion + "login";
  return new Promise<Register>((resolve, reject) => {
    api
      .post<registrationRes>(ep, loginObject)
      .then((res) => {
        if (res.status == 200 || res.status == 212) {
          resolve(res.data.user);
        }
      })
      .catch((reason: any) => {
        reject(reason);
      });
  });
};

//token exchange
export const JWTtokenExchange = (orgId: string, loginUserId: string) => {
  const ep = epFusion + `jwt/exchange/${orgId}/${loginUserId}`;
  return new Promise<string>((resolve, reject) => {
    api
      .get<JWTtokenExchangeRes>(ep, { headers: authHeader1() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.application_based_token.token);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//getOrg member
export const getOrgMember = (orgId: string) => {
  const ep = epFusion + `agency/${orgId}`;
  return new Promise<Member[]>((resolve, reject) => {
    api
      .get<getOrgMemberRes>(ep, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.user);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

/*************************** candidate DB  *********************************/
export const getCandidate = (cid:string) => {
  const ep = epCDB + `candidates/agency/id?id=${cid}`;
  return new Promise<Candidate>((resolve, reject) => {
    api
      .get<getCandidateRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.candidates);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getCandidates = (currentPage: number, pageSize: number,job_id?:string) => {
  let ep = epCDB + `candidates/agency?page=${currentPage}&per_page=${pageSize}`;
  if (job_id) {
    ep += `&job_id=${job_id}`;
  }  return new Promise<PaginationCandidates & paginationDetails>(
    (resolve, reject) => {
      api
        .get<getCandidatesRes>(ep, {
          headers: authHeader(),
        })
        .then((res) => {
          if (res.status == 200) resolve(res.data.candidates);
        })
        .catch((reason: any) => {
          throwError(reason);
          reject(reason);
        });
    }
  );
};

export const getLabels = (entity: string) => {
  const ep = epCDB + `labels/agency?entity=${entity}`;
  return new Promise<Label[]>((resolve, reject) => {
    api
      .get<getBoardLabelRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.labels);
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getCandidateLabel = (candidateID: string) => {
  const ep = epCDB + `labels/agency/candidate?id=${candidateID}`;
  return new Promise<Label[]>((resolve, reject) => {
    api
      .get<getBoardLabelRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.labels);
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const uploadAttachmentOfCandidate = (
  formData: any,
  modelName: string,
  recordId: string,
  org_id:string,
  user_id:string,
) => {
  const ep =
    epCDB + `attachments/agency/pub?model_name=${modelName}&record_id=${recordId}&org_id=${org_id}&user_id=${user_id}&type=form`;
  return new Promise<Attachment[]>((resolve, reject) => {
    api
      .post<uploadAttachmentOfCandidateRes>(ep, formData, {
        headers: atsUploadHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.attachments);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};


//get form by id
export const getFormById = (formId: string) => {
  const ep = epJD + `agency/form_builder` + `/${formId}`
  return new Promise<FormBuilder>((resolve, reject) => {
    api
      .get(ep, { headers: authHeader() })
      .then((res: any) => {
        if (res.status === 200) {
          resolve(res.data.form)
        }
      })
      .catch((reason: any) => {
        throwError(reason)
        reject(reason)
      })
  })
}


/************************************************ JD  *****************************/

export const getJDbyBoardId = (boardId: string) => {
  const ep = epJD + `agency/board/${boardId}`; 

  return new Promise<Jd>((resolve, reject) => {
    api.get(ep,{ headers: authHeader() }).then((res) => {
      if (res.status === 200) {
        resolve(res.data.jd);
      } else {
        reject(new Error('Failed to fetch job description'));
      }
    }).catch((reason: any) => {
      reject(reason);
    });
  });
};

/*********************************************** Campany creation  *********************************************/

//Function for fetching the all sources 
export const getAllSources = () => {
  const ep = epJD + "agency/source";
  return new Promise<Source[]>((resolve, reject) => {
    api
      .get<SourcesResponse>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.source_tracking);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
}

export const createQuickCandidate = (medium_type:string,req: any) => {
  const ep = epCDB +`candidates/agency/pub?medium_type=${medium_type}`;
  return new Promise<Candidate>((resolve, reject) => {
    api
      .post(ep, req, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          resolve(res.data.candidates);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

//getApplicantStatus
export const getApplicantStatus = (ids: { ids: string[] }) => {
  const ep = epCDB + "candidates/agency/bulk/activity"
  return new Promise((resolve, reject) => {
    api
      .post(ep, ids, {
        headers: authHeader(),
      },)
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.activities)
        }
      })
      .catch((reason: any) => {
        throwError(reason)
        reject(reason)
      })
  })
}

export const guestLogin = (base64:string) => {
  const ep = epFusion + `login/${base64}`;

  return new Promise<Login>((resolve, reject) => {
    api
      .post<guestLoginRes>(ep, null)
      .then((res) => {
        if (res.status == 200 || res.status == 212) {
          resolve(res.data.login);
        }
      })
      .catch((reason: any) => {
        reject(reason);
      });
  });
};

export const generateSecureLink = (base64:string) => {
  const ep = epFusion + `login/secret/regenerate?secret=${base64}`;

  return new Promise<Secret>((resolve, reject) => {
    api
      .post<generateSecureLinkRes>(ep, null)
      .then((res) => {
        if (res.status == 200 || res.status == 212) {
          resolve(res.data.secret);
        }
      })
      .catch((reason: any) => {
        reject(reason);
      });
  });
};


export const editCandidate = (candidateReq: any) => {
  const ep = epCDB + `candidates/agency`;

  return new Promise<Candidate>((resolve, reject) => {
    api
      .patch<getCandidateRes>(ep, candidateReq, { headers: authHeader() })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data.candidates);
        }
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};

export const getAttachments = (model: string, recordId: string) => {
  const ep = epCDB + `attachments/agency?model_name=${model}&record_id=${recordId}`;
  return new Promise<Attachment[]>((resolve, reject) => {
    api
      .get<getApplicantAttachmentsRes>(ep, {
        headers: authHeader(),
      })
      .then((res) => {
        if (res.status == 200) resolve(res.data.attachments);
      })
      .catch((reason: any) => {
        throwError(reason);
        reject(reason);
      });
  });
};