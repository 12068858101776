import { Avatar, Menu } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React from 'react';
import TacitbaseLogoWithLabel from './TacitbaseLogoWithLabel';
import TacitbaseAvtar from '../../ant/Avtar';
import { connect } from 'react-redux';
import { Opening } from '../../type/type';

const Navbar = (props: {
    currentOpening: Opening
    applicationId: string
    stripeCustomerStatus: any
    applicationName: string
    applicationImgUrl: string
  }) => {
    return (
             <Header
          style={{
            // display: 'flex',
            // alignItems: 'center',
            // color: 'white',
            // position: 'fixed',
            // top: 0,
            // zIndex: 1,
            // width: '100%',
            borderBottom: 'hsla(0,0%,100%,0.25)',
            borderBottomWidth: '1px',
            borderBottomStyle: 'solid'
          }}
          
        >
          <Menu
            selectedKeys={[]}
            theme='light'
            mode='inline'
            style={{ background: !props.currentOpening.color ? '#001529' : '', color: 'white', height: '52px' }}
          >
            <div className='flex space-x-2 h-full justify-between'>
              <TacitbaseLogoWithLabel />
              <div className='flex space-x-2'>
                
                  {props.applicationImgUrl ? (
                    <div>
                      {' '}
                      <TacitbaseAvtar size='small' src={props.applicationImgUrl} shape='square' />
                    </div>
                  ) : (
                    (
                    (
                      <div>
                        {' '}
                        <Avatar shape='square' size='small' className='bg-tacitbase-primary1 uppercase'>
                          {props.applicationName?.[0]}
                        </Avatar>
                      </div>
                    ))
                  )}
                

               <div> {props.applicationName}</div>
              </div>
              {/* <strong style={{ color: 'white', fontSize: '20px' }}>{TACITBASE}</strong> */}
            </div>
          </Menu>
        </Header>
    );
};

const mapStateToProps = (state: any) => ({
    applicationId: state.opening.applicationId,
    currentOpening: state.opening.currentOpening,
    applicationName: state.opening.applicationName,
    applicationImgUrl: state.opening.applicationImgUrl,
  
    stripeCustomerStatus: state.opening.billingCustomer.status
  })
  
  export default connect(mapStateToProps)(Navbar)
  