import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import configureAppStore from './redux';
import { configureAPI } from './lib/api';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './AgencyPortalApp';
import CustomThemeProvider from './CustomThemeProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
export const con = configureAppStore();
const onBeforeLift = () => {
  configureAPI(process.env.REACT_APP_BASE_URL);
};
root.render(
  <>
    {/* <App /> */}
    <Provider store={con.store}>
      <PersistGate
        loading={null}
        onBeforeLift={onBeforeLift}
        persistor={con.persistor}
      >
        <CustomThemeProvider >

          <App />
        </CustomThemeProvider >
      </PersistGate></Provider>

  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
