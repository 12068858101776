import React, { useEffect } from "react";
import TacitBaseModal from "../../../../../ant/TacitBaseModal";
import { Button, Pagination, Space } from "antd";
import { LabeledIconButton } from "../../../../../ant/Button";
import {
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";

import { Overlay } from "../../../../Overlay";
import { InitCurrentCandidateInAtsPool,InitCurrentCandidateLabelsInAtsPool } from "../../../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { getCandidateLabel ,getCandidate} from "../../../../../lib/api";
import { CurrentCandidateInPool } from "../../../../../redux/reducers/initialState";
import { Candidate, Label, Scope, loginUser }  from "../../../../../type/type.d";
import { PoolRoute } from "../../../../../constant";
import CandidateInPoolDetails from "./CandidateInPoolDetails";
;


const DetailedPoolApplicant = (props: {
  selectedRow: string;
  applicationId: string;
  candidatePool: any;
  currentCandidateInPool: CurrentCandidateInPool;
  loginUser: loginUser;
  InitCurrentCandidateLabel: (labels: Label[]) => void;
  InitCurrentCandidateInDB: (candidate: Candidate) => void;
}) => {
  const history = useHistory();
  const params: { board_id: string; candidate_id: string; tabname: string } =
    useParams();
  const itemRender = (current: any, type: any) => {
    if (type === "prev") {
      return (
        <LabeledIconButton
          icon={<LeftOutlined />}
          onClick={() => { }}
          label=""
        />
      );
    }
    if (type === "next") {
      return (
        <LabeledIconButton
          icon={<RightOutlined />}
          onClick={() => { }}
          label=""
        />
      );
    }

    if (type === "page") {
      return null;
    }
    return null;
  };

  const backToPool = () => {
    history.push({
      pathname: `/o/${props.applicationId}/b/${params.board_id}/pool`,
    });
  };

  useEffect(() => {
    params.candidate_id &&
      (getCandidate(params.candidate_id).then((res) => {
        props.InitCurrentCandidateInDB(res);
      }),
        getCandidateLabel(params.candidate_id).then((res) => {
          props.InitCurrentCandidateLabel(res);
        }));
  }, [params.candidate_id]);

  const handleTableChange = (current: number) => {
    history.push({
      pathname: `/o/${props.applicationId}/b/${params.board_id}/${PoolRoute}/${Object.keys(props.candidatePool)[current - 1]
        }`,
    });
  };
  return (
    <>
      <TacitBaseModal
        keyboard={false}
        maskClosable={false}
        visibility={params.candidate_id ? true : false}
        width={"100%"} 
        title={
          <>
            <Overlay onClose={backToPool} scope={Scope.Dialog}>
              <div
                className={`w-full flex justify-between items-center py-3    
                 
                `}
                style={{ height: 52 }}
              >
                <div className="">
                <Button
                 style={{
                  color:  "#000000" ,
                  transition: "color 0.3s",
                }}
                className=""
                  icon={<LeftOutlined   />} 
                  type="link"
                  name="Back to pool"
                  onClick={backToPool} 
                >{" "}Back to pool</Button>
                </div>
                
              </div>{" "}
            </Overlay>
          </>
        }
        closeIcon={null}
        content={
          <>

            <div
             style={{
              position: "relative",
              zIndex: 1,
              overflowY: "hidden",
              width: "100%",
              maxWidth: "100%",
              height: "100%",
              maxHeight: "100%",
              background: "white",
              backdropFilter: "none",
              transition: "transform 270ms ease",
              display: "flex",
              flexDirection: "column",
            }}
            >
              <div className="h-full w-full  ">
                <div
                  className="flex flex-col h-full flex-shrink-0  border-l border-b border-t border-r  "
                >
                  <CandidateInPoolDetails />{" "}
                </div>
              </div>
            </div>
          </>

          // </Overlay >
        }
        footer={<div className="m-1"></div>}
        bodyStyle={{
          width: "60vw",
          height: "68vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          overflow: "hidden",
        }}
        style={{
          width: " 100vw",
          height: "100vh",
          position: "fixed",
          bottom: "50px",
          top:"50px",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
          padding: "0px",
          borderRadius:'4px'
        }}
        centered
        className="review-modal-content"
      />
    </>
  );
};
const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  loginUser: state.opening.loginUser,
  candidatePool: state.opening.candidatePool,
  currentCandidateInPool: state.opening.currentCandidateInPool,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  InitCurrentCandidateInDB: (candidate: Candidate) =>
  dispatch(InitCurrentCandidateInAtsPool(candidate)), 
  InitCurrentCandidateLabel: (labels: Label[]) =>
    dispatch(InitCurrentCandidateLabelsInAtsPool(labels)),
});

export default connect(mapStateToProps, mapPropsToState)(DetailedPoolApplicant);
