// import { getKey } from "./keyboard";
import {
    Scope,
    ShortcutEvent,
    Handler,
    AddHandlerParams,
    ShortcutHandler,
    RegisterShortcutHandlerOptions,
    Keys,
  } from "../../type/type.d";
  
  const HandlerMap = new Map<Scope, Handler[]>();
  const getScopedHandlers = (): Handler[] => {
    const sortedRegistrars = [...HandlerMap.entries()].sort(
      (a, b) => b[0] - a[0]
    );
  
    let results: Handler[] = [];
    for (const [scope, handlers] of sortedRegistrars) {
      results = results.concat(handlers);
      if (handlers.length && scope >= Scope.Dialog && scope !== Scope.Universal) {
        break;
      }
    }
    return results;
  };
  
  export const onKeyboardEvent = (event: ShortcutEvent): void => {
    for (const { callback, key } of getScopedHandlers()) {
      if (!key) {
        callback(event);
        event.preventDefault();
        event.stopPropagation();
        unregisterShortcutHandler(callback);
      } else if (event.key === "Escape") {
        callback(event);
        event.preventDefault();
        event.stopPropagation();
        unregisterShortcutHandler(callback);
      }
      if (event.defaultPrevented) {
        break;
      }
    }
  };
  const addEventListeners = () => {
    document.addEventListener("keydown", onKeyboardEvent);
    document.addEventListener("keypress", onKeyboardEvent);
  };
  
  const removeEventListeners = () => {
    document.removeEventListener("keydown", onKeyboardEvent);
    document.removeEventListener("keypress", onKeyboardEvent);
  };
  
  export const unregisterShortcutHandler = (callback: ShortcutHandler) => {
    for (const [scope, handlers] of HandlerMap.entries()) {
      HandlerMap.set(
        scope,
        handlers.filter((handler) => handler.callback !== callback)
      );
    }
  };
  
  const addHandler = ({ callback, scope, clearScope, key }: AddHandlerParams) => {
    addEventListeners();
  
    let handlers: Handler[] = [];
  
    if (!clearScope && HandlerMap.has(scope)) {
      handlers = HandlerMap.get(scope) || [];
    }
  
    handlers.unshift({ callback, key });
    HandlerMap.set(scope, handlers);
  };
  
  /**
   * Adds a shortcut handler for a specific scope
   */
  export const registerShortcutHandler = (
    callback: ShortcutHandler,
    options?: RegisterShortcutHandlerOptions
  ) => {
    const {
      scope = Scope.Default,
      clearScope = false,
      key = Keys.Escape,
    } = options || {};
    addHandler({ callback, scope, clearScope, key });
  };
  
  export const clearShortcuts = () => {
    HandlerMap.clear();
    removeEventListeners();
  };
  