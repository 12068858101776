import React from 'react';
import { Result } from 'antd';

const PageNotFound = () => {
 

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
      />
    </div>
  );
};

export default PageNotFound;
