import { Attachment, Label } from "../../type/type";

export function init_current_candidate_in_ats_pool(state: any, action: any) {
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      candidate: action.candidate,
    },
  };
}

export function init_current_candidate_labels_in_ats_pool(
  state: any,
  action: any
) {
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      labels: action.labels,
    },
  };
}

export function init_candidates_ats_pool(state: any, action: any) {
  let candidates: any = {};

  action.data.length > 0
    ? action.data.map((candidate: any) => {
        if (candidate) {
          candidate.labels = candidate.labels
            ? candidate.labels.map((label: Label) => label.id)
            : [];
          candidate.members = candidate.members ? candidate.members : [];

          candidates[candidate.id] = candidate;
        }
      })
    : (candidates = {});
  return {
    ...state,
    candidatePool: { ...state.candidatePool, ...candidates },

    //   reviewComments: {},
    //   reviewAttachments: {},
  };
}

export function add_label_to_pool_candidate_in_ats(state: any, action: any) {
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      labels: [...state.currentCandidateInPool.labels, action.data],
    },
    candidateLabels: {
      ...state.candidateLabels,
      [action.data.id]: action.data,
    },
  };
}

export function remove_label_from_pool_candidate_in_ats(
  state: any,
  action: any
) {
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      labels: state.currentCandidateInPool.labels
        ? state.currentCandidateInPool.labels.filter(
            (label: Label) => label.id != action.labels
          )
        : [],
    },
  };
}

export function add_pool_candidate_attachment(
  state: any,
  action: {
    data: Attachment[];
  }
) {
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      attachments: action.data,
    },
  };
}

export function add_new_pool_candidate_attachment(
  state: any,
  action: {
    data: Attachment[];
  }
) {
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      attachments: [
        ...state.currentCandidateInPool.attachments,
        ...action.data,
      ],
    },
  };
}

export function add_attachment_upload_status(state: any, action: any) {
  return {
    ...state,
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      newAttachmentUploader: action.data,
    },
  };
}

export function remove_label_from_pool_candidate(state: any, action: any) {
  let candidatePool: any = {};
  action.data.map((poolLabels: any) => {
    candidatePool[poolLabels] = {
      ...state.candidatePool[poolLabels],
      labels: state.candidatePool[poolLabels].labels
        ? state.candidatePool[poolLabels].labels.filter(
            (label: string) => !action.labels.includes(label)
          )
        : state.candidatePool[poolLabels].labels,
    };
  });

  return {
    ...state,
    candidatePool: {
      ...state.candidatePool,
      ...candidatePool,
    },
  };
}



export function add_labels_on_multiple_ats_pool_candidates(state: any, action: any) {
  let candidateInAtsPool: any = {};
  let candidateLabels: string[] = [];
  action.data.map((reviewLabels: any) => {
    (candidateLabels = reviewLabels.labels.map((l: Label) => l.id)),
      (candidateInAtsPool[reviewLabels.id] = {
        ...state.candidatePool[reviewLabels.id],
      labels: state.candidatePool[reviewLabels.id].labels ? [
          ...state.candidatePool[reviewLabels.id].labels,
          ...candidateLabels,
      ] : [],
      });
  });
  return {
    ...state,
    candidatePool: {
      ...state.candidatePool,
     ...candidateInAtsPool
    },
  };
}


export function add_Candidate_In_Pool(state: any, action: any) {
    let newCandidates :any = {};
    if (Array.isArray(action.data)) {
      action.data.forEach((candidate: any) => {
        newCandidates[candidate.id] = candidate;
      });
    } else {
      newCandidates = { [action.data.id]: action.data };
    }

    return {
      ...state,
      candidatePool: { ...newCandidates, ...state.candidatePool },
    };
}