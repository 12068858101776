import { AGENCIES, CDBMember, OTHER, REFERRAL, THIRD_PARTY } from "../../constant";
import { Source } from "../../lib/apiRes"
import {
  Attachment,
  Candidate,
  commentTagType,
  InitLabel,
  Label,
  Member,
  openingMemberType,
} from "../../type/type.d";
import {
  reviewAttachments, currentCDBactivity,
  candidateDB,
} from "./initialState";
export function add_Candidate_In_DB(state: any, action: any) {
  let key: string[] = Object.keys(state.candidateDB);
  let objKey = key.length > 0 ? key[0] : 1;
  return {
    ...state,
    candidateDB: {
      ...state.candidateDB,
      [objKey]: {
        [action.data.id]: action.data,
        ...state.candidateDB[objKey],
      },
    },
  };
}
export function add_CandidateDB_Table_header(state: any, action: any) {
  return {
    ...state,
    candidateDBTableHeader: [...state.candidateDBTableHeader, action.data],
  };
}
export function init_candidate_in_review(state: any, action: any) {
  var candidatesInReview: any = {};
  action.data.length > 0
    ? action.data.map((candidate: any) => {
        candidate.labels = candidate.labels
          ? candidate.labels.map((label: Label) => label.id)
          : [];
        candidate.members = candidate.members ? candidate.members : [];
        candidatesInReview[candidate.id] = candidate;
      })
    : (candidatesInReview = {});

  return {
    ...state,
    candidatesInReview: action.reviewTag === state.candidatesInReview.reviewed
      ? {
        ...state.candidatesInReview,
        reviews: {
          ...state.candidatesInReview.reviews,
          ...candidatesInReview,
        },
      }
      : {
        reviewed: action.reviewTag,
        reviews: candidatesInReview
      },
    candidateDB: candidateDB,
  };
}
export function add_candidate_to_review(state: any, action: any) {
  var candidateReview: any = {};
  if (action.reviewTag === state.candidatesInReview.reviewed) {
    action.data.length > 0
      ? action.data.map((candidate: any) => {
          if (candidate.reviewed)
            candidate.labels = candidate.labels
              ? candidate.labels.map((label: Label) => label.id)
              : [];
          candidate.members = candidate.members ? candidate.members : [];
          candidateReview[candidate.id] = candidate;
        })
      : (candidateReview = state.candidatesInReview);
  }


  return {
    ...state,
    candidatesInReview:
      action.reviewTag === state.candidatesInReview.reviewed
        ? {
            ...state.candidatesInReview,
            reviews: {
              ...state.candidatesInReview.reviews,
              ...candidateReview,
            },
          }
        : state.candidatesInReview,
  };
}
export function init_current_candidate_in_review(state: any, action: any) {
  return {
    ...state,
    currentCandidateInReview: action.data,
    reviewAttachments: reviewAttachments,
  };
}

export function init_candidate_labels(state: any, action: any) {
  let candidateLabels: any = {};
  action.data.map((label: Label) => {
    candidateLabels[label.id] = label;
  });
  return {
    ...state,
    candidateLabels: candidateLabels,
  };
}
export function update_candidate_labels(state: any, action: any) {
  return {
    ...state,
    candidateLabels: {
      ...state.candidateLabels,
      [action.data.id]: action.data,
    },
  };
}

export function add_candidate_labels(state: any, action: any) {
  return {
    ...state,
    candidateLabels: {
      ...state.candidateLabels,
      [action.data.id]: action.data,
    },
  };
}

export function add_member_on_CDB(state: any, action: any) {
  var CDBMembers: openingMemberType[] = [];
  action.data.map((member: Member) => {
    CDBMembers.push({
      id: member.id,
      access: member.access,
    });
  });
  return {
    ...state,
    servicesMembership: {
      ...state.servicesMembership,
      [CDBMember]: state.servicesMembership[CDBMember]
        ? [...state.servicesMembership[CDBMember], ...CDBMembers]
        : CDBMembers,
    },
  };
}

export function init_CDB_member(state: any, action: any) {
  var CDBMembers: any[] = [];
  action.data.map((member: Member) => {
    CDBMembers.push({ id: member.id, access: member.access });
  });
  return {
    ...state,
    servicesMembership: {
      ...state.servicesMembership,
      [CDBMember]: CDBMembers,
    },
  };
}
export function init_CDBtable_head(state: any, action: any) {
  return {
    ...state,
    candidateDBTableHeader: action.data,
  };
}

export function init_candidates(state: any, action: any) {
  let candidates: any = {};
  action.data.map((candidate: Candidate) => {
    if (candidate)
    candidates[candidate.id] = candidate;
  });
  return {
    ...state,
    candidateDB:
      Object.keys(state.candidateDB).length < 3
        ? { ...state.candidateDB, [action.currentpage]: candidates }
        : { [action.currentpage]: candidates },
    reviewComments: {},
    reviewAttachments: {},
  };
}

export function edit_candidate(state: any, action: any) {
  return {
    ...state,
    candidateDB: {
      ...state.candidateDB,
      [action.currentPage]: {
        ...state.candidateDB[action.currentPage],
        [action.data.id]: action.data,
      },
    },
  };
}

export function init_review_attachments(
  state: any,
  action: {
    data: Attachment[];
    reviewId: string;
  }
) {
  return {
    ...state,
    reviewAttachments: {
      [action.reviewId]: action.data,
    },
  };
}
export function init_candidate_attachments(
  state: any,
  action: {
    data: Attachment[];
  }
) {
  let attachments: { [key: string]: Attachment } = {};
  action.data.length > 0
    ? action.data.map(
        (attchment: Attachment) => (attachments[attchment.id] = attchment)
      )
    : attachments;
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      attachments: attachments,
    },
  };
}

export function init_review_comments(
  state: any,
  action: {
    data: any;
    reviewId: string;
    tag: string;
  }
) {
  let reviewComment: any = {};
  action.data.map((a: any) => {
    a["tag"] = action.tag;
    reviewComment[a.id] = a;
  });
  return {
    ...state,
    reviewComments: {
      [action.reviewId]: {
        ...state.reviewComments[action.reviewId],
        ...reviewComment,
      },
    },
  };
}

export function add_review_comment(
  state: any,
  action: {
    data: commentTagType;
    tag: string;
  }
) {
  action.data["tag"] = action.tag;
  return {
    ...state,
    reviewComments:state.currentCandidateInReview.id==action.data.review_id? {
      ...state.reviewComments,
      [action.data.review_id]: {
        ...state.reviewComments[action.data.review_id],
        [action.data.id]: action.data,
      },
    }:state.reviewComments,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: {
        ...state.candidatesInReview.reviews,
        [action.data.review_id]: {
          ...state.candidatesInReview.reviews[action.data.review_id],
          comment_count:state.candidatesInReview.reviews[action.data.review_id].comment_count+1
        }
      }
    }
  };
}

export function init_current_candidate_labels(state: any, action: any) {
  var label: string[] = [];
  action.data.map((member: Member) => {
    label.push(member.id);
  });
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      labels: label,
    },
    currentCDBactivity: currentCDBactivity,
    currentCDBAtsActivity: currentCDBactivity,
  };
}
export function init_current_candidate_in_db(state: any, action: any) {
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      candidateId: action.candidateId,
    },
    currentCDBactivity: currentCDBactivity,
    currentCDBAtsActivity: currentCDBactivity,
  };
}
export function add_label_current_candidate_in_db(state: any, action: any) {
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      labels: [...state.currentCandidateInEditMode.labels, action.data.id],
    },
    candidateLabels: {
      ...state.candidateLabels,
      [action.data.id]: action.data,
    },
  };
}

export function add_labels_to_candidate_in_review(state: any, action: any) {
  return {
    ...state,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: {
        ...state.candidatesInReview.reviews,
        [action.data.reviewId]: {
          ...state.candidatesInReview.reviews[action.data.reviewId],
          labels: state.candidatesInReview.reviews[action.data.reviewId].labels
            ? state.candidatesInReview.reviews[
                action.data.reviewId
              ].labels.concat(action.data.label.id)
            : [action.data.label.id],
        },
      },
    },
    reviewLabels: {
      ...state.reviewLabels,
      [action.data.label.id]: action.data.label,
    },
    currentCandidateInReview: {
      ...state.currentCandidateInReview,
      labels: [...state.currentCandidateInReview.labels, action.data.label],
    },
  };
}

export function add_review_attachment(
  state: any,
  action: {
    data: Attachment[];
    reviewId: string;
  }
) {
  return {
    ...state,
    reviewAttachments: {
      ...state.reviewAttachments,
      [action.reviewId]: state.reviewAttachments[action.reviewId]
        ? state.reviewAttachments[action.reviewId].concat(...action.data)
        : [...action.data],
    },
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: {
        ...state.candidatesInReview.reviews,
        [action.reviewId]: {
          ...state.candidatesInReview.reviews[action.reviewId],
          attachment_count:
            state.candidatesInReview.reviews[action.reviewId].attachment_count +
            1,
        },
      },
    },
    currentCandidateInReview: {
      ...state.currentCandidateInReview,
      attachment_count: state.currentCandidateInReview.attachment_count + 1,
    },
  };
}

export function add_candidate_attachment(
  state: any,
  action: {
    data: Attachment[];
  }
) {
  let attachments: { [key: string]: Attachment } = {};
  action.data.length > 0
    ? action.data.map(
        (attchment: Attachment) => (attachments[attchment.id] = attchment)
      )
    : attachments;
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      attachments: {
        ...state.currentCandidateInEditMode.attachments,
        ...attachments,
      },
    },
  };
}
export function update_attachment_of_candidate(state: any, action: any) {
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      attachments: {
        ...state.currentCandidateInEditMode.attachments,
        [action.data.id]: {
          ...state.currentCandidateInEditMode.attachments[action.data.id],
          name: action.data.name,
        },
      },
    },
  };
}
export function delete_attachment_of_candidate(state: any, action: any) {
  let candidateAttchments: any = {};
  let copiedattachment = Object.values(
    state.currentCandidateInEditMode.attachments
  ).filter((attchment: any) => attchment.id !== action.data);
  copiedattachment.map((attchment: any) => {
    candidateAttchments[attchment.id] = attchment;
  });
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      attachments: candidateAttchments,
    },
  };
}

export function init_review_labels(state: any, action: any) {
  let labels: InitLabel = {};
  action.data.map((label: Label) => {
    labels[label.id] = label;
  });
  return {
    ...state,
    reviewLabels: labels,
  };
}
export function update_review_labels(state: any, action: any) {
  return {
    ...state,
    reviewLabels: {
      ...state.reviewLabels,
      [action.data.id]: action.data,
    },
  };
}

export function add_reviews_labels(state: any, action: any) {
  return {
    ...state,
    reviewLabels: {
      ...state.reviewLabels,
      [action.data.id]: action.data,
    },
  };
}

export function add_member_on_review(state: any, action: any) {
  var members: string[] = [];
  action.data.map((member: Member) => {
    members.push(member.id);
  });
  return {
    ...state,
    currentCandidateInReview: {
      ...state.currentCandidateInReview,
      members: [...state.currentCandidateInReview.members, ...members],
    },
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: {
        ...state.candidatesInReview.reviews,
        [action.reviewId]: {
          ...state.candidatesInReview.reviews[action.reviewId],
          members: [
            ...state.candidatesInReview.reviews[action.reviewId].members,
            ...members,
          ],
        },
      },
    },
  };
}

export function remove_member_on_review(state: any, action: any) {
  return {
    ...state,
    currentCandidateInReview: {
      ...state.currentCandidateInReview,
      members: state.currentCandidateInReview.members.filter(
        (member: string) => member !== action.data[0].id
      ),
    },
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: {
        ...state.candidatesInReview.reviews,
        [action.reviewId]: {
          ...state.candidatesInReview.reviews[action.reviewId],
          members: state.candidatesInReview.reviews[action.reviewId].members.filter(
            (member: string) => member !== action.data[0].id
          ),
        },
      },
    },
  };
}

export function update_cdb_member_role(state: any, action: any) {
  return {
    ...state,
    servicesMembership: {
      ...state.servicesMembership,
      CDBMember: state.servicesMembership.CDBMember
        ? state.servicesMembership.CDBMember.map((m: any) =>
            m.id == action.memberId
              ? {
                  ...m,
                  access: action.access,
                }
              : m
          )
        : [],
    },
  };
}

export function delete_cdb_member(state: any, action: any) {
  return {
    ...state,
    servicesMembership: {
      ...state.servicesMembership,
      CDBMember: state.servicesMembership.CDBMember
        ? state.servicesMembership.CDBMember.filter(
            (m: any) => m.id != action.memberId
          )
        : [],
    },
  };
}

export function add_labels_on_multiple_review(state: any, action: any) {
  let reviews: any = {};
  let Rlabels: string[] = [];
  action.data.map((reviewLabels: any) => {
    (Rlabels = reviewLabels.labels.map((l: Label) => l.id)),
      (reviews[reviewLabels.id] = {
        ...state.candidatesInReview.reviews[reviewLabels.id],
      labels: state.candidatesInReview.reviews[reviewLabels.id].labels ? [
          ...state.candidatesInReview.reviews[reviewLabels.id].labels,
          ...Rlabels,
      ] : [],
      });
  });
  return {
    ...state,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: {
        ...state.candidatesInReview.reviews,
        ...reviews,
      },
    },
  };
}

export function add_members_on_multiple_review(state: any, action: any) {
  let reviews: any = {};
  let Rmembers: string[] = [];
  action.data.map((reviewLabels: any) => {
    (Rmembers = reviewLabels.members.map((l: Label) => l.id)),
      (reviews[reviewLabels.id] = {
        ...state.candidatesInReview.reviews[reviewLabels.id],
        members: [
          ...state.candidatesInReview.reviews[reviewLabels.id].members,
          ...Rmembers,
        ],
      });
  });

  return {
    ...state,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: {
        ...state.candidatesInReview.reviews,
        ...reviews,
      },
    },
  };
}
export function update_comment_of_review(state: any, action: any) {
  return {
    ...state,
    reviewComments:state.reviewComments[action.data.review_id]? {
      ...state.reviewComments,
      [action.data.review_id]: {
        ...state.reviewComments[action.data.review_id],
        [action.data.id]: {
          ...state.reviewComments[action.data.review_id][action.data.id],
          body: action.data.body,
        },
      },
    }:state.reviewComments,
  };
}
export function update_state_of_close_review(state: any, action: any) {
  return {
    ...state,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: {
        ...state.candidatesInReview.reviews,
        [action.reviewId]: {
          ...state.candidatesInReview.reviews[action.reviewId],
          reviewed: !state.candidatesInReview.reviews[action.reviewId].reviewed,
        },
      },
    },
    currentCandidateInReview: {
      ...state.currentCandidateInReview,
      reviewed: true
    }
  };
}
export function current_CDB_activity(state: any, action: any) {
  let reviewComment: any = {};
  action.comments.map((a: any) => {
    a["tag"] = action.tag;
    reviewComment[a.id] = a;
  });

  return {
    ...state,
    currentCDBactivity: {
      ...action.review,
      ["comments"]: action.review.id == state.currentCDBactivity.id ? {
        ...state.currentCDBactivity["comments"],
        ...reviewComment,
      } : { ...reviewComment },
    },
  };
}
export function current_CDB_review_activity(state: any, action: any) {
  let reviewComment: any = {};
  action.comments.map((a: any) => {
    a["tag"] = action.tag;
    reviewComment[a.id] = a;
  });

  return {
    ...state,
    currentCDBactivity: {
      ...state.currentCDBactivity,
      ["comments"]: {
        ...state.currentCDBactivity["comments"],
        ...reviewComment,
      },
    },
  };
}
export function current_CDB_ATS_activity(state: any, action: any) {
  return {
    ...state,
    currentCDBAtsActivity: {
      [action.candidateApplicants.id]: action.candidateApplicants,
    },
  };
}
export function remove_label_from_review(state: any, action: { reviewId: string, labels: string[] }) {
  return {
    ...state,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: {
        ...state.candidatesInReview.reviews,
        [action.reviewId]: {
          ...state.candidatesInReview.reviews[action.reviewId],
          labels: state.candidatesInReview.reviews[action.reviewId].labels
            ? state.candidatesInReview.reviews[action.reviewId].labels.filter(
                (label: string) => !action.labels.includes(label)
              )
            : [],
        },
      },
    },
    currentCandidateInReview: {
      ...state.currentCandidateInReview,
      labels: state.currentCandidateInReview.labels
        ? state.currentCandidateInReview.labels.filter(
          (label: Label) => label.id !== action.labels[0]
        )
        : [],
    },

  };
}
export function remove_label_from_reviews(state: any, action: any) {
  let reviews: any = {};
  action.data.map((reviewLabels: any) => {
    reviews[reviewLabels] = {
      ...state.candidatesInReview.reviews[reviewLabels],
      labels: state.candidatesInReview.reviews[reviewLabels].labels
        ? state.candidatesInReview.reviews[reviewLabels].labels.filter(
            (label: string) => !action.labels.includes(label)
          )
        : state.candidatesInReview.reviews[reviewLabels].labels,
    };
  });
  return {
    ...state,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: {
        ...state.candidatesInReview.reviews,
        ...reviews,
      },
    },
  };
}

export function remove_label_from_candidate(state: any, action: any) {
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      labels: state.currentCandidateInEditMode.labels
        ? state.currentCandidateInEditMode.labels.filter(
            (label: string) => !action.labels.includes(label)
          )
        : [],
    },
  };
}

// plugin

export function init_mappings(state: any, action: any) {
  var mappings: any = {};

  action.data.map((mapping: any) => {
    mappings[mapping.id] = mapping;
  });
  return {
    ...state,
    pluginData: { ...state.pluginData, mappings: mappings },
  };
}

export function update_mapping(state: any, action: any) {
  return {
    ...state,
    pluginData: {
      ...state.pluginData,
      mappings: {
        ...state.pluginData.mappings,
        [action.data.map_id]: action.data.value,
      },
    },
  };
}

export function init_sources(state: any, action: any) {
  let sources: { [key: string]: {name:string,profile:string} } = {}
  action.payload.map((source: Source) => {
    switch (source.strategy_name) {
      case REFERRAL:
        sources[source.tb_src] = {
          "name": state.loginUser.user.id==source.name?state.members[source.name]?.first_name + " " + state.members[source.name]?.last_name+" (You)":state.members[source.name]?.first_name + " " + state.members[source.name]?.last_name,
          "profile":state.members[source.name]?.image_url
        }
        break
      case THIRD_PARTY:
        sources[source.tb_src] = {
           "name": source.name,
           "profile":state.jobPlatform[source.name]
         }
        break
      case AGENCIES:
      case OTHER:
        sources[source.tb_src] = {
        "name": source.name,
        "profile": state.applicationImgUrl
      };
    break;
    }
  })
  return {
    ...state,
    candidateSources: sources,
  }
}


export function applicant_status(state: any, action: any) {
  let applicants: { [key: string]: string[] } = {}
  action.payload.map((applicant: { id: string, trackers: string[] }) => {
    applicants[applicant.id] = applicant.trackers
  })
  return {
    ...state,
    applicantStatus: applicants
  }
}