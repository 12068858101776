import React, { useEffect, useState } from 'react'
import { useDispatch, connect } from 'react-redux'
import { Opening, OrganizedBoard } from './type/type'
import { Col, Row } from 'antd'
import BoardPreview from './BoardPreview'
import {  getOpenings, getOrgMember } from './lib/api'
import { initMembership, listOfBoards } from './redux/actions/opening/action'
import BoardLoader from './components/boardPanel/BoardLoader'
import { FETCHING_SHARED_POSTS } from './constant'

export type PageContentSectionNameProps = {
  openings: OrganizedBoard // Assuming you have a prop named openings
  applicationId:string
}
const Openings = (props: PageContentSectionNameProps) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
  
    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const openings = await getOpenings();
          const orgMembers = await getOrgMember(props.applicationId);
          dispatch(initMembership(orgMembers));
          dispatch(listOfBoards(openings));
        } catch (error) {
          console.error(error);
        } finally {
          setTimeout(() => {
            setIsLoading(false);
          }, 0); // Optional delay
        }
      };
  
      fetchData();
    }, []);
  let orgOpenings = Object.values(props.openings)[0].openings

  return (
    <div className='overflow-y-scroll h-full m-4  p-4 bg-white'>
      {isLoading ? (
        <div className='overflow-y-scroll h-full  bg-white rounded '>
          <BoardLoader active={true} text={FETCHING_SHARED_POSTS} />
        </div>
      ) : (<>
        <Row gutter={[10, 10]} wrap className='w-full '>
          {Object.values(orgOpenings).length > 0 && (
            Object.values(orgOpenings).map(
              (board: Opening) =>
                board && (
                  <Col key={board.id} xs={{ span: 8 }} lg={{ span: 8 }} xl={{ span: 6 }} xxl={{ span: 4 }}>
                    <BoardPreview
                      key={board.id}
                      boards={props.openings.pageSection.openings[board.id]} // Assuming openings is your Redux state containing boards
                    />
                  </Col>
                )
            )
          ) }
        </Row>
         {Object.values(orgOpenings).length == 0&&(
            <div className='h-full w-full flex items-center justify-center'>
              <BoardLoader active={false} text='No content shared yet.' />
            </div>)}
          </>
      )}
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  openings: state.opening.openings ,// Assuming openings is your Redux state containing boards
  applicationId: state.opening.applicationId

})
export default connect(mapStateToProps, null)(Openings)
