import React, { useState } from 'react'
import { Button, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { generateSecureLink } from './lib/api'
import { CheckCircleOutlined } from '@ant-design/icons'
import Link from 'antd/es/typography/Link'

const { Title, Text } = Typography

const GenerateNew = () => {
  const history = useHistory()
  const [isGenerated, setIsGenerated] = useState(false)
  const [loading, setLoading] = useState(false) // New state for loading

  const handleGenerateNew = async () => {
    const sessionKey = await sessionStorage.getItem('sessionKey')

    if (sessionKey) {
      setLoading(true) // Set loading to true when request starts
      try {
        await generateSecureLink(sessionKey)
        setIsGenerated(true)
      } catch (error) {
        console.error('Failed to generate secure link:', error)
        // Handle error here (e.g., show a notification or message)
      } finally {
        setLoading(false) // Set loading to false after request completes
      }
    }
  }

  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-50 p-4'>
      <div className='max-w-md w-full bg-white shadow-lg rounded-lg p-6 sm:p-8 text-center'>
        <div className='mb-4 sm:mb-6'>
          {/* <img src="/path/to/your/illustration.png" alt="Email Sent" className="w-full" /> */}
        </div>
        {isGenerated ? (
          <>
            <CheckCircleOutlined style={{ fontSize: '48px', color: '#52c41a', marginBottom: '16px' }} />
            <Title level={3} className='text-gray-800 mb-4'>
              Check your email
            </Title>
            <Text className='text-gray-600 mb-4 block'>
              We&apos;ve sent you a new secure link. Please check your inbox to continue. If you haven&apos;t received an email in 5
              minutes, check your spam folder or{' '}
              <Link
                style={{ width: 'max-content' }}
                onClick={() => {
                  setIsGenerated(false)
                }}
              >
                try resend
              </Link>
              .
            </Text>
          </>
        ) : (
          <>
            <Title level={3} className='text-gray-800 mb-4'>
              Expired link
            </Title>
            <Text className='text-gray-600 mb-4'>It looks like your session has expired.</Text>
            <div>
              <Button
                type='primary'
                className='mt-4'
                style={{ width: '100%' }}
                onClick={handleGenerateNew}
                loading={loading} // Use the loading prop
              >
                Request secure link
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default GenerateNew
