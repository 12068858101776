import React from 'react'
import { Modal } from 'antd'
import { ModalFuncProps } from 'antd/lib/modal'

interface CustomModalProps extends ModalFuncProps {
    // Customize content of the modal
    content: React.ReactNode,
    // Text for the OK button
    okText?: string,
    // Text for the Cancel button
    cancelText?: string,
    // Callback function for the OK button
    handleOk?: () => void,
    // Callback function for the Cancel button
    handleCancel?: () => void,
    // Button element to be rendered
    // ModalTriggerButtonName: React.ReactNode
    // setModalState: (state: boolean) => void;
    visibility: boolean
}

const TacitBaseModal: React.FC<CustomModalProps> = ({
    content,
    okText = 'OK',
    visibility,
    // setModalState,
    cancelText = 'Cancel',
    handleOk,
    handleCancel,
    // ModalTriggerButtonName,
    ...modalProps
}) => {

    return (
        <>
            {/* {ModalTriggerButtonName} */}
            <Modal
                open={visibility}
                onOk={handleOk}
                onCancel={handleCancel}
                okText={okText}
                cancelText={cancelText}
                {...modalProps}
            >
                {content}
            </Modal>
        </>
    )
}
export default TacitBaseModal 