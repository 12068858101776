import React, { useEffect } from "react";
import PoolTable from "./PoolTable";

import { getAllSources, getLabels } from "../../../../../lib/api";
import { CANDIDATES } from "../../../../../constant";
import { Jd, Label } from "../../../../../type/type";
import { Dispatch } from "redux";
import { initCandidateLabel, initSources } from "../../../../../redux/actions/opening/action";
import { connect, useDispatch } from "react-redux";
type poolProps = {
  currentTrackerJd: Jd,
  initCandidateLabels: (labels: Label[]) => void;


};

const Pool = (props:poolProps) => { 
  const dispatch = useDispatch();
 
  useEffect(()=>{
    getLabels(CANDIDATES).then((res) => {
      props.initCandidateLabels(res);
    })
    getAllSources().then((sources) => {
      dispatch(initSources(sources))
    })

  },[])  
  return (
    <div className="   h-full flex flex-col">
      {/* <TabAction>
        <div className="h-10 flex justify-end items-center ">
        <Space>
        <QuickAddCandidate/>
        </Space>
        </div>
      </TabAction> */}
      <PoolTable />
    </div>
  );
};


const mapPropsToState = (dispatch: Dispatch) => ({
  initCandidateLabels: (labels: Label[]) =>
    dispatch(initCandidateLabel(labels)),
 

  
});
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  candidatePool: state.opening.candidatePool,
  currentTrackerJd: state.opening.currentTrackerJd,

  applicationId: state.opening.applicationId,
});
export default connect(mapStateToProps,mapPropsToState)(Pool);

