import React, { useEffect } from 'react'
import { Button, Image, Layout, Space } from 'antd'
import { InfoCircleOutlined, LinkOutlined, EyeOutlined } from '@ant-design/icons'
const { Header, Content } = Layout
import Footer from './components/Footer';
import Title from "antd/es/typography/Title";
import Tacitbase from "./images/Tacitbase_WT_8.png"

const LandingPage: React.FC = () => {
  useEffect(() => {
    sessionStorage.setItem("current_tab_application_token", 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImE5YmU0YjE4OCJ9.eyJhdWQiOiI4YWQ4YTFhZC05YzhmLTQzNGQtYjg1My01ZWFkMjA1ZDlkN2EiLCJleHAiOjE3MjMyODA1MjUsImlhdCI6MTcyMjkyMDUyNSwiaXNzIjoidGFjaXRiYXNlLmNvbSIsInN1YiI6IjI2MWQwOTBkLWI2NTMtNDliNi04MWYxLWQ0OWQyZmU2MTVmMiIsImp0aSI6IjIyODc2NTk0LTJhM2YtNDNjNy1iZGQ5LTA3YjY0YjE4OGIzMiIsImF1dGhlbnRpY2F0aW9uVHlwZSI6IkpXVF9TU08iLCJlbWFpbCI6InJ1dGlrKzEyMzRAdGFjaXRiYXNlLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJncmF0ZWZ1bC1wb3N0IiwiYXBwbGljYXRpb25JZCI6IjhhZDhhMWFkLTljOGYtNDM0ZC1iODUzLTVlYWQyMDVkOWQ3YSIsInJvbGVzIjpbIkd1ZXN0Il0sImF1dGhfdGltZSI6MTcyMjkyMDUyNSwidGlkIjoiNGQxNjA3OTYtN2EwMC00YWFjLTk3YmEtMjg0NGQ2ZGZkYWE0Iiwic2VydmljZXMiOlsiYXRzIiwiY2FsZW5kYXIiLCJhY3Rpdml0eSJdLCJzb3VyY2UiOiJ0YWNpdGJhc2UiLCJ0eXBlIjp0cnVlLCJ1c2VySWQiOiIyNjFkMDkwZC1iNjUzLTQ5YjYtODFmMS1kNDlkMmZlNjE1ZjIiLCJ1c2VyX3R5cGUiOiJndWVzdCIsImFwcGxpY2F0aW9uTmFtZSI6IkFsaWJhYmEiLCJ1c2VybmFtZSI6ImdyYXRlZnVsLXBvc3QiLCJhY3RpdmF0ZWQiOnRydWV9.DY-qDpt5rT5ZljWwhZ_-nxOpiqMOk9_Bg-mDPMUKgvg')
  }, [])

  return (
    <Layout className='min-h-screen'>
      <Header className='shadow-md flex justify-between items-center px-4'>
        <Space style={{ width: 192 }}>
          <img src={Tacitbase} alt="Tacitbase Logo" width="36px" height="36px" />
          <Title level={3} style={{ margin: 0, color: "white" }}>
            Tacitbase
          </Title>
        </Space>
      </Header>

      <Content className='flex-grow'>
        <section className='bg-gradient-to-r from-purple-500 to-blue-500 text-white text-center py-20'>
          <h1 className='text-4xl font-bold mb-4'>Agency Portal</h1>
          <p className='text-lg mb-6'>Invite vendors directly through the Tacitbase web app.</p>
        </section>

        <section className='py-20'>
          <div className='container mx-auto text-center'>
            <h2 className='text-3xl font-bold mb-8'>How It Works</h2>
            <div className='flex justify-around items-start'>
              <div className='max-w-sm'>
                <InfoCircleOutlined className='text-blue-500' style={{ fontSize: 20 }} />
                <h3 className='text-xl font-semibold mb-2'>Get the Link via Email</h3>
                <p>Receive an email with a secure link to access the candidate pool.</p>
              </div>
              <div className='max-w-sm'>
                <LinkOutlined className='text-blue-500' style={{ fontSize: 20 }} />
                <h3 className='text-xl font-semibold mb-2'>Click on the Link</h3>
                <p>Click the link to securely access the pool in your browser.</p>
              </div>
              <div className='max-w-sm'>
                <EyeOutlined className='text-blue-500' style={{ fontSize: 20 }} />
                <h3 className='text-xl font-semibold mb-2'>Add Candidates to the Pool</h3>
                <p>Follow and track the information as it updates in real-time.</p>
              </div>
            </div>
          </div>
        </section>
      </Content>

      <Footer />
    </Layout>
  )
}

export default LandingPage
