// CustomThemeProvider.tsx

import React, { ReactNode } from 'react'
import { ConfigProvider } from 'antd'
import { defaultTheme } from './themeConfig'

interface CustomThemeProviderProps {
    children: ReactNode
}

const CustomThemeProvider: React.FC<CustomThemeProviderProps> = ({ children }) => {
    const mergedTheme = {
        token: {
            colorPrimary: defaultTheme.colorPrimary,
            borderRadius: defaultTheme.borderRadius,
            colorPrimaryBorder: defaultTheme.colorPrimaryBorder,
            lineWidthFocus: defaultTheme.lineWidthFocus,
            fontFamily: defaultTheme.typography.fontFamily,
            colorTextDisabled:defaultTheme.colorTextDisabled
        },
        components: {
            Button: {
                colorPrimary: defaultTheme.Button?.colorPrimary || defaultTheme.Button.colorPrimary,
                algorithm: defaultTheme.Button?.algorithm || defaultTheme.Button.algorithm,
            },
            Select: {
                optionSelectedFontWeight: defaultTheme.Select.optionSelectedFontWeight
            },
            Layout: {
                headerHeight: defaultTheme.Layout.headerHeight,
                headerPadding: defaultTheme.Layout.headerPadding
            },
            upload: {
                actionsColor: defaultTheme.upload.actionsColor
            }
        },
    }

    return <ConfigProvider theme={mergedTheme}>{children}</ConfigProvider>
}

export default CustomThemeProvider
