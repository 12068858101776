import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Candidate, FormBuilder, loginUser } from '../../type/type.d'
import { Modal, Form } from 'antd'
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter'
import { LabeledIconButton } from '../../ant/Button'
import { ADD_CANDIDATE, CANDIDATE_ADDED_SUCCESS, CANDIDATES, PRIVATE_MODE, TACITBASEQA_MEDIUM } from '../../constant'
import { Dispatch } from 'redux'
import { addCandidateInPool } from '../../redux/actions/opening/action'
import { OrgInfo } from '../../lib/apiRes'
import FormHosting from '../formBuilder.tsx/FormHosting'
import { createQuickCandidate, uploadAttachmentOfCandidate } from '../../lib/api'
import { throwSuccess } from '../../service/throwError'
import { UploadProps } from 'antd/lib'

const QuickAddCandidate = (props: {
  addCandidateInPool: (candidate: any) => void
  currentTrackerJd: any
  loginUser: loginUser
  org: OrgInfo
  currentTrackerFrom: FormBuilder
}) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setLoadingForApiCalling] = useState<boolean>(false)
  const [fileList, setFileList] = useState<any[]>([])
  const [form] = Form.useForm()
  const submitData = () => {
    form
      .validateFields()
      .then((value: any) => {
        onCreateCandidate(value, form.getFieldValue('file'));
      })
      .catch((err: any) => {
        console.log('form value error', err)
      })
  }
  const onCreateCandidate = (req: any, file: any) => {
    setLoadingForApiCalling(true)
    createQuickCandidate(PRIVATE_MODE, {
      ...req,
      source: {
        jd: props.currentTrackerJd.id,
        medium: TACITBASEQA_MEDIUM
      }
    })
      .then((res: Candidate) => {
        setLoadingForApiCalling(false)
        setOpen(false)
        props.addCandidateInPool(res)
        if (fileList.length>0) {
          const formData = new FormData();
          formData.append('file', file.file);
          uploadAttachmentOfCandidate(formData, CANDIDATES, res.id, props.org.id, props.loginUser.user.id)
            .then((res) => {
              if (res) {
                throwSuccess(CANDIDATE_ADDED_SUCCESS)
              }
            })
            .catch(() => {
              setLoadingForApiCalling(false)
            })
        } else {
          throwSuccess(CANDIDATE_ADDED_SUCCESS)
        }
        
      })
      .catch(() => {
        setLoadingForApiCalling(false)
      })
  }
  const propsUpload: UploadProps = {
    onRemove: () => {
      setFileList([])
    },
    beforeUpload: (file) => {
      setFileList([ file])
      return false
    },
    fileList,
  }
  
  const handleModalClose = ()=>{
    form.resetFields();
    setOpen(false)
    setFileList([])
  }
  return (
    <>
      <Modal visible={open} title={capitalizeFirstLetter(ADD_CANDIDATE)} afterClose={handleModalClose} onCancel={() => setOpen(false)} footer={null}>
        <Form form={form} layout='vertical'>
          <FormHosting formRef = {form}
           formData={props.currentTrackerFrom.form_data.elements}
            onSubmit={submitData}
             isLoading={isLoading} 
             fileList = {fileList}
             propsUpload = {propsUpload}
             />
        </Form>
      </Modal>
      <div className=''>
        <LabeledIconButton
          label='Create'
          size='small'
          type="default" className="h-7 "
          onClick={() => setOpen(true)}          
        />
      
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addCandidateInPool: (candidate: any) => dispatch(addCandidateInPool(candidate))
})

const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  currentTrackerJd: state.opening.currentTrackerJd,
  loginUser: state.opening.loginUser,
  org: state.opening.currentOrg,
  currentTrackerFrom: state.opening.currentTrackerForm
})

export default connect(mapStateToProps, mapDispatchToProps)(QuickAddCandidate)
