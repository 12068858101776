import { Member, openingMemberType } from "../../type/type";

export function init_members(state: any, action: any) {
  var members: any = {};

  action.data?.map((member: any) => {
    members[member.id] = member;
  });
  return {
    ...state,
    members: members,
  };
}
export function init_applicant_members(state: any, action: any) {
  return {
    ...state,
    applicantMembers: {
      ...state.applicantMembers,
      [action.applicantId]: action.data,
    },
  };
}
export function remove_member_from_card(state: any, action: any) {
  return {
    ...state,
    applicantMembers: {
      ...state.applicantMembers,
      [action.applicantID]: state.applicantMembers[action.applicantID].filter(
        (member: any) => member !== action.memberID
      ),
    },
  };
}
export function add_member_on_card(state: any, action: any) {
  let members: string[] = [];
  action.data.length > 0 ? action.data.map((member: Member) => (
    members.push(member.id)
  )) : members
  return {
    ...state,
    applicantMembers: {
      ...state.applicantMembers,
      [action.applicantID]: state.applicantMembers[action.applicantID]
        ? [...state.applicantMembers[action.applicantID], ...members]
        : [members],
    },
  };
}
export function add_creator_on_card(state: any, action: any) {

  return {
    ...state,
    applicantMembers: {
      ...state.applicantMembers,
      [action.applicantID]: state.applicantMembers[action.applicantID]
        ? [...state.applicantMembers[action.applicantID], action.data]
        : [action.data],
    },
  };
}
export function add_member_on_board(state: any, action: any) {
  var members: any[] = [];

  action.data.map((member: Member) => {
    members.push({ id: member.id, access: member.access });
  });
  return {
    ...state,
    openingMembers: {
      ...state.openingMembers,
      [action.openingID]: state.openingMembers[action.openingID]
        ? [...state.openingMembers[action.openingID], ...members]
        : [...members],
    },
  };
}
export function remove_member_from_opening(state: any, action: any) {
  return {
    ...state,
    openingMembers: {
      ...state.openingMembers,
      [action.openingId]: state.openingMembers[action.openingId].filter(
        (member: openingMemberType) => member.id !== action.memberID
      ),
    },
  };
}

export function update_member_details(state: any, action: any) {
  return {
    ...state,
    members: {
      ...state.members,
      [action.data.memberID]: {
        ...state.members[action.data.memberID],
        [action.data.key]: action.data.value,
      },
    },
  };
}

export function init_analytics_labels(state: any, action: any) {
  var analyticsLabels: any = {};
 
  action.data?.map((label: any) => {
    analyticsLabels[label.id] = label;
  });
  return {
    ...state,
    analayticsLabels: analyticsLabels,
  };
}

export function add_org_member(state: any, action: any) {
  // Retrieve current members from state
  const currentMembers = { ...state.members };
  const filteredInvites = [...state.outgoingInvitations]?.filter((invite:any) => {
    // Check if any invitee's email matches your email
    return invite?.invitee[0]?.email !== action?.data?.email;
  });
  // Add the new member to the members object
  currentMembers[action.data?.id] = action?.data;

  // Return the updated state with the new member added
  return {
    ...state,
    members: currentMembers,
    outgoingInvitations:filteredInvites
  };
}
