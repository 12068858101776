import { Space } from "antd";
import Title from "antd/es/typography/Title";
import React from "react";
import Tacitbase from "../../images/Tacitbase_WT_8.png";

const TacitbaseLogoWithLabel = () => {
    return (
        <>
            <div className="flex items-center justify-center" >
            <Space style={{width: 192}}>
                <img src={Tacitbase} alt="" width="36px" height="36px" />
                <Title level={3} style={{ margin: 0, color: "white" }}>
                    {"Tacitbase"}
                </Title>
            </Space>   
            </div>
        </>
    )
}
export default TacitbaseLogoWithLabel
