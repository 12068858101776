import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  Collapse,
  ConfigProvider,
  Divider,
  Flex,
  Input,
  List,
  Space,
  Tag,
  Typography,
} from "antd";
import parse from "html-react-parser";
import { Candidate, InitLabel, Label } from "../../../../../type/type";
import { capitalizeFirstLetter } from "../../../../../utils/capitalizeFirstLetter";
import {
  CheckOutlined,
  CopyOutlined,
  ExportOutlined,
  MailOutlined,
  PhoneOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { determineTextColor } from "../../../../../utils/CalculateBrightnessOfColor";
// import CandidateInPoolAttachments from "./CandidateInPoolAttachments";
import { CurrentCandidateInPool } from "../../../../../redux/reducers/initialState";
import { editCandidate } from "../../../../../lib/api";
import { throwSuccess } from "../../../../../service/throwError";
import { validateInput } from "../../../../../utils/Validations";
import Link from "antd/es/typography/Link";
import { NOT_AVAILABLE, regexPatterns } from "../../../../../constant";
import CandidateInPoolAttachments from "./CandidateInPoolAttachments";
const { Title, Text } = Typography;

type Props = {
  currentCandidateInPool: CurrentCandidateInPool;
  candidateLabels: InitLabel;
  sources:{[key:string]:{name:string,profile:string}},
};

const CandidateInPoolDetails = (props: Props) => {
  const [candidate, setCandidate] = useState<any>(
    props.currentCandidateInPool.candidate
  );
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [initialCandidate, setInitialCandidate] = useState<Candidate | null>(
    null
  );
  const [saveStatus, setSaveStatus] = useState<string>(""); // Save status state
  const [errors, setErrors] = useState<{ [key: string]: string }>({}); // Error state
  const [modifiedValues, setModifiedValues] = useState<{ [key: string]: any }>(
    {}
  );

  // Update candidate data and initial candidate data when props change

  useEffect(() => {
    setCandidate(props.currentCandidateInPool.candidate);
    setInitialCandidate(props.currentCandidateInPool.candidate);
  }, [props.currentCandidateInPool]);

  // Handle the click to edit a field
  const handleEditClick = (key: string) => {
    setEditingKey(key);
  };


  // Validate input fields
  const validateInputValue = (
    key: string,
    value: string,
    view: string
  ): string => {
    const pattern = regexPatterns[key];

    if (pattern && !pattern.test(value)) {
      if (key == "email") return `The  ${key.replace(/_/g, " ")} is invalid.`;
      else return `The  ${view.replace(/_/g, " ")} URL is invalid.`;
    }
    return "";
  };

  // Handle input changes for both predefined and custom fields
  const handleInputChange = (
    key: string,
    event: React.ChangeEvent<HTMLInputElement>,
    isCustom: boolean,
    view: string
  ) => {
    isCustom &&
      setCandidate((prevCandidate: any) => ({
        ...prevCandidate,
        customs: {
          ...prevCandidate.customs,
          [key]: event.target.value,
        },
      }));

    !isCustom &&
      setCandidate((prevCandidate: any) => ({
        ...prevCandidate,
        [key]:
          key == "notice_period_in_days"
            ? parseInt(event.target.value)
            : event.target.value,
      }));
    const value = event.target.value;

    if (value.trim().length > 0) {

      const errorMessage = validateInputValue(key, value, view);
      // Track modified values
      if (errorMessage) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: errorMessage,
        }));
      } else {
        setModifiedValues((prevModifiedValues) => ({
          ...prevModifiedValues,
          [key]: key == "notice_period_in_days" ? parseInt(value) : value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [key]: "",
        }));
      }
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [key]: "",
      }));
    }
  };

  const handleInputBlur = () => {
    handleEditClick(""); // Close editing mode
    const hasErrors = Object.values(errors).some((error) => error !== "");

    if (initialCandidate && !hasErrors) {
      // Check if there are any modified values
      if (Object.keys(modifiedValues).length === 0) {
        return; // No changes, exit early
      }

      // Construct the candidate request object
      let candidateReq = {
        ...modifiedValues,
        id: props.currentCandidateInPool.candidate.id,
      };
      // Check for errors before making API call
      setSaveStatus("saving"); // Show saving status

      editCandidate(candidateReq)
        .then((res) => {
          setCandidate(res);
          setTimeout(() => {
            setSaveStatus("saved"); // Show saved status
            throwSuccess("Candidate updated successfully.");
          }, 1000);
          setTimeout(() => setSaveStatus(""), 2000); // Reset save status
          setModifiedValues({}); // Clear modified values

          setInitialCandidate(candidate); // Update initialCandidate to current candidate after successful save
        })
        .catch(() => {
          setSaveStatus(""); // Reset save status
          setCandidate(initialCandidate); // Reset candidate to initial state
        });
    } else {
      // Reset value and error state of fields with errors

      Object.keys(errors)?.forEach((key) => {

        if (errors[key]) {
          setCandidate((prevCandidate: any) => ({
            ...prevCandidate,
            [key]: "", // Reset value of the field
          }));
          setErrors((prevErrors) => ({
            ...prevErrors,
            [key]: "", // Reset error of the field
          }));
        }
      });
    }
  };

  const customKeys = Object.keys(candidate?.customs);

  // Define the structure for predefined and custom fields
  const predefinedFields = [
    { name: "linked_in", type: "url", view: "LinkedIn" },
    { name: "github", type: "url", view: "GitHub" },
    { name: "figma", type: "url", view: "Figma" },
    { name: "dribbble", type: "url", view: "Dribbble" },
    { name: "leetcode", type: "url", view: "LeetCode" },
    { name: "personal_blog", type: "url", view: "Personal blog" },
    { name: "behance", type: "url", view: "Behance" },
    { name: "research_gate", type: "url", view: "ResearchGate" },
    { name: "google_scholar", type: "url", view: "Google Scholar" },
    { name: "twitter", type: "url", view: "Twitter" },
    { name: "stack_overflow", type: "url", view: "Stack Overflow" },
    { name: "skills", type: "paragraph", view: "Skills" },
    { name: "location", type: "paragraph", view: "Location" },
    { name: "highest_education", type: "paragraph", view: "Highest education" },
    { name: "latest_experience", type: "paragraph", view: "Latest experience" },
    {
      name: "notice_period_in_days",
      type: "number",
      view: "Notice period (Days)",
    },
  ];

  // Handle Enter key press to save changes
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleInputBlur();
    }
  };

  // Render editable field based on the current editing key
  const renderEditableField = (field: any) => {
    return (
      <>
        {editingKey === field.name ? (
          <div>
            <Input
              value={candidate[field.name]}
              type={field.type}
              onChange={(event) =>
                handleInputChange(field.name, event, false, field.view)
              }
              onBlur={handleInputBlur}
              onKeyDown={handleKeyDown}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            {errors[field.name] && (
              <Text type="danger" style={{ fontSize: "12px" }}>
                {errors[field.name]}
              </Text>
            )}
          </div>
        ) : (
          <div
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            onClick={() => handleEditClick(field.name)}
            style={{}}
            className="truncate relative"
          >
            <div
              style={{
                boxSizing: "border-box",
                margin: 0,
                padding: "5px 12px",
                fontSize: "14px",
                lineHeight: "1.5714285714285714",
                fontFamily: "Inter",
                display: "inline-block",
                width: "100%",
                minWidth: 0,
                borderRadius: "4px",
                height: 32,
                transition: "all 0.2s",
              }}
            >
              {" "}
              {candidate[field.name] || "Type here"}
            </div>

            {field.type === "url" && candidate[field.name] && (
              <span
                tabIndex={0}
                role="button"
                onKeyDown={() => {}}
                className=" absolute right-0 top-1 bg-white pl-4"
              >
                <Link href={candidate[field.name]} target="_blank">
                  <ExportOutlined />
                </Link>
              </span>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {saveStatus && (
        <div
          style={{
            position: "fixed",
            top: 62,
            left: 510,
            width: "100%",
            padding: "10px",

            color: "black",
            textAlign: "left",
            zIndex: 1000,
          }}
        >
          {saveStatus === "saving" ? (
            <span className=" w-full font-bold" style={{ color: "#009D79" }}>
              <SyncOutlined
                spin={true}
                style={{ color: "#009D79", marginRight: 4 }}
              />{" "}
              Saving changes...
            </span>
          ) : (
            <span style={{ color: "#009D79" }} className="font-bold ">
              <CheckOutlined
                size={100}
                style={{ color: "#009D79", marginRight: 4 }}
                type="success"
              />
              Changes saved
            </span>
          )}
        </div>
      )}
      <div className="h-full flex flex-col mb-4 " >
        <div className="border-b">
        <Title
          level={4}
          className="bg-white z-10 flex items-center mb-0 px-4 pt-2 text-xl h-10 "
        >
          <div className="w-full flex space-x-2">
            <div className=" w-max truncate" style={{ maxWidth: "50%" }}>
              {editingKey === "first_name" ? (
                <>
                  <Input
                    value={candidate.first_name}
                    onChange={(event) =>
                      handleInputChange("first_name", event, false, "")
                    }
                    onBlur={handleInputBlur}
                    onKeyDown={handleKeyDown}
                    style={{ width: "max-content", maxWidth: "100%" }}
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                  />

                  <Text type="danger" style={{ fontSize: "12px" }}>
                    {validateInput(candidate.first_name, "first name")}
                  </Text>
                </>
              ) : (
                <span
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  className="pt-1 cursor-pointer truncate w-full"
                  onClick={() => handleEditClick("first_name")}
                >
                  {capitalizeFirstLetter(candidate.first_name)}
                </span>
              )}
            </div>
            <div className=" w-max truncate" style={{ maxWidth: "50%" }}>
              {editingKey === "last_name" ? (
                <Input
                  value={candidate.last_name}
                  onChange={(event) =>
                    handleInputChange("last_name", event, false, "")
                  }
                  onBlur={handleInputBlur}
                  onKeyDown={handleKeyDown}
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                />
              ) : (
                <span
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  className="pt-1 cursor-pointer truncate"
                  onClick={() => handleEditClick("last_name")}
                >
                  {capitalizeFirstLetter(candidate.last_name)}
                </span>
              )}
            </div>
          </div>
        </Title></div>

        <div
          className="h-full pl-4 pb-4 pr-2 pt-1.5 overflow-y-scroll"
          style={{
            margin: 2,
          }}
        >
          <div className="h-full flex flex-col items-start ">
          {props.currentCandidateInPool.labels.length > 0 && (<>
            <Space direction="vertical">
              <Flex gap="4px 0" wrap="wrap">
                {props.currentCandidateInPool.labels.length > 0 &&
                  props.currentCandidateInPool.labels.map((label: Label) => (
                    <Tag
                      color={props.candidateLabels?.[label.id]?.color}
                      className="flex items-center h-7 max-w-full"
                      key={label.id}
                    >
                      <Text
                        ellipsis={{
                          tooltip: props.candidateLabels?.[label.id]?.name,
                        }}
                        style={{
                          color:
                            props.candidateLabels?.[label.id]?.color &&
                            determineTextColor?.(
                              props.candidateLabels?.[label.id]?.color
                            ),
                          maxWidth: "100%",
                        }}
                      >
                        {props.candidateLabels?.[label.id]?.name}
                      </Text>
                    </Tag>
                  ))}
              </Flex>
            </Space>
            <Divider className="my-2" /></>)}
            <div className="w-full h-full">
              <div style={{ width: "100%" }} className="space-x- flex justify-between ">
                <div style={{ width: "100%" }} className="space-x-2 flex">
                  <Text strong>Source:</Text>
                  <Text
                    style={{
                      width: "70%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    ellipsis={{ tooltip: props.sources[candidate.source.from]? capitalizeFirstLetter(props.sources[candidate.source.from].name):capitalizeFirstLetter(candidate.source.from)}}
                  >
                     {(() => {
                      const sourceKey = candidate.source.from ;
                      if ( sourceKey?.trim()?.length==0) {
                        return NOT_AVAILABLE;
                      }
                      return props.sources[sourceKey] ? capitalizeFirstLetter(props.sources[sourceKey].name) : capitalizeFirstLetter(sourceKey);
                    })()}
                  </Text>
                </div>
                <Text
                  style={{
                    width: "80%",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                  ellipsis={{ tooltip: candidate.source.medium && candidate.source.medium }}
                  className="space-x-2"
                >
                  <Text strong>Medium:</Text>
                  <Text disabled={candidate.source.medium ? false : true}>
                    {candidate.source.medium && capitalizeFirstLetter(candidate.source.medium) || "Not available"}
                  </Text>
                </Text>
              </div>
              <Divider className="my-2" />

              <Space direction="vertical" className="w-full">
                <div style={{ width: "100%" }} className="justify-between flex">
                  <div
                    className="space-x-1 flex items-center w-full "
                  >
                    <div>
                      <MailOutlined />
                    </div>

                    {editingKey === "email" ? (
                      <div className="w-full">
                        <Input
                          className="ml-1"
                          value={candidate.email}
                          onChange={(event) =>
                            handleInputChange("email", event, false, "email")
                          }
                          onBlur={handleInputBlur}
                          onKeyDown={handleKeyDown}
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus
                        />
                        {errors["email"] && (
                          <Text type="danger" style={{ fontSize: "12px" }}>
                            {errors["email"]}
                          </Text>
                        )}
                      </div>
                    ) : (
                      <div className="relative w-auto">
                        <Text
                          style={{
                            boxSizing: "border-box",
                            margin: 0,
                            padding: "5px 12px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            lineHeight: "1.5714285714285714",
                            fontFamily: "Inter",
                            display: "inline-block",
                            minWidth: 0,
                            borderRadius: "4px",
                            height: 26,
                            transition: "all 0.2s",
                          }}
                          copyable={{
                            text: "",
                            icon: (
                              <span className="absolute z-10 right-0 bottom-1.5">
                                <CopyOutlined />
                              </span>
                            ),
                            tooltips: false,
                          }}
                          role="button"
                          tabIndex={0}
                          onClick={() => handleEditClick("email")}
                        >
                          {candidate.email || "Type here"}
                        </Text>
                      </div>
                    )}
                  </div>
                  <div
                    className="flex items-center ml-4"
                    style={{
                      width: "80%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <div>
                      <PhoneOutlined />
                    </div>
                    {editingKey === "phone" ? (
                      <div>
                        <Input
                          className="ml-1"
                          value={candidate.phone}
                          onChange={(event) =>
                            handleInputChange("phone", event, false, "")
                          }
                          onBlur={handleInputBlur}
                          onKeyDown={handleKeyDown}
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus
                        />                       
                      </div>
                    ) : (
                      <div className="truncate">
                        <Text
                          style={{
                            boxSizing: "border-box",
                            margin: 0,
                            padding: "4px 16px",
                            fontSize: "14px",
                            lineHeight: "1.5714285714285714",
                            fontFamily: "Inter",
                            display: "inline-block",
                            width: "100%",
                            minWidth: 0,
                            borderRadius: "4px",
                            height: 30,
                            transition: "all 0.2s",
                          }}
                          ellipsis={{ tooltip: candidate.phone }}
                          role="button"
                          tabIndex={0}
                          onClick={() => handleEditClick("phone")}
                        >
                          {candidate.phone || "Type here"}
                        </Text>
                      </div>
                    )}
                  </div>
                </div>
                <CandidateInPoolAttachments />
                {candidate.description && (
                  <ConfigProvider
                    theme={{
                      components: {
                        Collapse: {
                          headerPadding: "6px 0px",
                        },
                      },
                    }}
                  >
                    <Collapse ghost>
                      <Collapse.Panel
                        header={<Text strong>Description</Text>}
                        key={"Description"}
                      >
                        <p>{parse(candidate.description)}</p>
                      </Collapse.Panel>
                    </Collapse>
                  </ConfigProvider>
                )}
              </Space>
              <List
                itemLayout="horizontal"
                dataSource={predefinedFields}
                renderItem={(item) => (
                  <List.Item style={{ padding: "8px 0px 0px 0px" }}>
                    <List.Item.Meta
                      title={capitalizeFirstLetter(item.view)}
                      description={renderEditableField(item)}
                    />
                  </List.Item>
                )}
              />

              <Divider className="my-2" />
              {customKeys.length > 0 && (
                <List
                  itemLayout="horizontal"
                  dataSource={customKeys.map((key) => ({
                    title: key,
                    content: candidate.customs[key],
                  }))}
                  renderItem={(item) => (
                    <List.Item style={{ padding: "8px 0px 0px 0px" }}>
                      <List.Item.Meta
                        title={capitalizeFirstLetter(item.title)}
                        description={
                          editingKey === item.title ? (
                            <Input
                              value={candidate.customs[item.title]}
                              onChange={(event) =>
                                handleInputChange(item.title, event, true, "")
                              }
                              onBlur={handleInputBlur}
                              onKeyDown={handleKeyDown}
                              // eslint-disable-next-line jsx-a11y/no-autofocus
                              autoFocus
                            />
                          ) : (
                            <div
                              style={{
                                boxSizing: "border-box",
                                margin: 0,
                                padding: "5px 12px",
                                fontSize: "14px",
                                lineHeight: "1.5714285714285714",
                                fontFamily: "Inter",
                                display: "inline-block",
                                width: "100%",
                                minWidth: 0,
                                borderRadius: "4px",
                                height: 26,
                                transition: "all 0.2s",
                              }}
                              className="truncate"
                              role="button"
                              tabIndex={0}
                              onKeyDown={() => {}}
                              onClick={() => handleEditClick(item.title)}
                            >
                              {candidate.customs[item.title]}
                            </div>
                          )
                        }
                      />
                    </List.Item>
                  )}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentCandidateInPool: state.opening.currentCandidateInPool,
  applicationId: state.opening.applicationId,
  candidateLabels: state.opening.candidateLabels,
  members: state.opening.members,
  sources: state.opening.candidateSources,
});

export default connect(mapStateToProps)(CandidateInPoolDetails);
