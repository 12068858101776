import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"
import { Avatar, Divider, Row, Tag, Typography, Badge } from "antd"
import { capitalizeFirstLetter, generateTooltipForMember } from "./utils/capitalizeFirstLetter"
import { MEMBER_ONLY, PUBLIC, TrackerRoute } from "./constant"
import TacitbaseAvtar from "./ant/Avtar"
import { Opening, OrganizedBoard, orgMember } from "./type/type"
const { Title } = Typography
type BoardPreviewProps = {
  boards: Opening,
  openings: OrganizedBoard,
  applicationId: string,
  members: orgMember,
  notificationCenter: any
}
const BoardPreview = (props: BoardPreviewProps) => {
  const [boardDots, setBoardDots] = useState<any>()
  useEffect(() => {
    setBoardDots(props.notificationCenter.unreadDotsofServices.ats.boards)
  }, [props.notificationCenter])
  const history: any = useHistory()

  const showBoard = (id: string) => {
    history.push({
      pathname: `/o/${props.applicationId}/b/${id}/pool`,
      method: "get",
    })
  }

  return (
    <React.Fragment>
      <div className="w-auto bg-white rounded border">
        <div className={`bg-cover bg-center bg-no-repeat rounded-t flex-col  ${!props.boards.background_image && `bg-color-${props.boards.color}`}  w-full h-20`} style={props.boards.background_image ? { backgroundImage: `url(${props.boards.color}&w=200&dpr=2)` } : {}}
          onClick={() => showBoard(props.boards.id)}
          role="button"
          data-testid="board-preview"
          tabIndex={0}
          onKeyDown={() => showBoard(props.boards.id)}
        >
          <div style={{ backgroundColor: "#00000030" }} className="h-full rounded-t border relative">
            <Title level={5} className="px-3 py-2" style={{ color: "white" }}
              ellipsis={{ tooltip: `${props.boards.name}` }}>{props.boards.name}</Title>

            <Row justify={"end"}>
              <Tag className="bg-white bg-opacity-70 "   >
                {props.boards.member_only
                  ? capitalizeFirstLetter(MEMBER_ONLY)
                  : capitalizeFirstLetter(PUBLIC)}
            </Tag>
            </Row>
            { (boardDots ?
                  boardDots[props.boards.id] ? (  <div className="absolute top-0 right-1.5"> <Badge status="processing" color="white" size="default" /></div>):null:null)}
          </div>
        </div>


        <div className="px-3 py-2 flex items-center justify-between">
          <div className="flex items-center">
          {props.boards.member_id && props.members[props.boards.member_id] &&
              <Avatar.Group>{props.members[props.boards.member_id].image_url ?
                <TacitbaseAvtar toolTip={generateTooltipForMember(props.members[props.boards.member_id])} src={props.members[props.boards.member_id].image_url} />
                : <TacitbaseAvtar toolTip={generateTooltipForMember(props.members[props.boards.member_id])} content={props.members[props.boards.member_id].first_name[0] + props.members[props.boards.member_id].last_name[0]} />
              }</Avatar.Group>}
            { }
            {props.boards.members.map((memberId:string) => memberId !== props.boards.member_id).length > 0 && <Divider type="vertical" style={{ borderColor: "gray" }} />}
            {props.boards.members && <>

              <Avatar.Group maxCount={4} size={"small"}>
                {props.boards.members.map((memberId:string) => memberId && props.members[memberId] && props.members[memberId].registrations[0].data.activated && (
                  <>{props.members[memberId].image_url ?
                    <TacitbaseAvtar toolTip={generateTooltipForMember(props.members[memberId])} src={props.members[memberId].image_url} />
                    : <TacitbaseAvtar toolTip={generateTooltipForMember(props.members[memberId])} content={props.members[memberId].first_name[0] + props.members[memberId].last_name[0]} />
                  }</>))}
              </Avatar.Group>
            </>

            }
          </div>
          {/* {props.boards.default_template &&
            <Popover content={<JobBoardShortViewAnalytic analytic={props.boards.analytics} />}>
              <Button size="small" icon={<BarChartOutlined   />}></Button>
            </Popover>
          } */}
        </div>


      </div>
    </React.Fragment>
  )
}
const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
  notificationCenter: state.opening.notificationCenter,
  members: state.opening.members,
  openings: state.opening.openings,

})
export default connect(mapStateToProps)(BoardPreview)
